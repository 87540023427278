import React, { Component } from "react";
import { withCookies, Cookies } from "react-cookie";
import { Navigate } from "react-router-dom";
import { instanceOf } from "prop-types";
import { connect } from "react-redux";
import { get as _get } from "lodash";

import { withRouter } from "../../components/hoc/withRouter";

import { deleteOauthToken } from "../../actions/oauth";

class Logout extends Component {

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  componentDidMount() {
    const { deleteOauthToken, cookies } = this.props;

    if (cookies) { cookies.remove("tknData", { path: "/" }); }

    if (typeof deleteOauthToken === "function") { deleteOauthToken(); }
  };

  render() {
    const { location } = this.props;
    const from = _get(location, "state.from", "/");

    return (
      <Navigate
        to={{
          pathname: "/sign-in",
          search: (from !== "/") ? `?continue=${from}` : ""
        }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  token: _get(state, "oauth.token"),
});

const mapDistachToProps = (dispatch) => ({
  deleteOauthToken: () => dispatch(deleteOauthToken()),
});

export default withRouter(connect(mapStateToProps, mapDistachToProps)(withCookies(Logout)));