import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";

import { withRouter } from "../../../components/hoc/withRouter";

import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhonelinkRingIcon from "@mui/icons-material/PhonelinkRing";

class Verification extends Component {

  constructor(props) {
    super(props);

    this.state = {
      errors: {}
    };
  }

  render() {
    const { handleNext } = this.props;

    return (
      <Container component="div" sx={{ maxWidth: "100% !important", padding: "0 !important" }}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: {
              sm: 8,
              xs: 4,
            },
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
          }}>

          <Typography component="h1" variant="h1" sx={{ mb: 1 }}>
            <FormattedMessage id="verification.title" defaultMessage="Verification" />
          </Typography>
          <Typography component="p" variant="h5" sx={{ mb: 5 }}>
            Provide information about your restaurant for its webpage
          </Typography>

          <Grid container spacing={3}>
            <Grid item md={10} sm={12} xs={12} sx={{ mx: "auto" }}>
              <Box component="div" className="d-flex align-items-center" sx={{ border: "1px solid #DADADA", p: 2, flexDirection: { md: "row", xs: "column" } }}>
                <MailOutlineIcon sx={{ fontSize: 50, mr: { md: 3 } }} />

                <Box component="div">
                  <Typography className="fst-italic" component="h4" variant="h4" sx={{ mb: { md: 0.5, xs: 2 }, mt: { md: 0, xs: 2 }, textAlign: { md: "left", xs: "center" } }}>
                    Send Email
                  </Typography>
                  <Typography component="p" variant="body" className="text-md-start text-center" sx={{ color: "#858585" }}>
                    Send a confirmation email in order to verify your personal information
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item md={10} sm={12} xs={12} sx={{ mx: "auto" }}>
              <Box component="div" className="d-flex align-items-center" sx={{ border: "1px solid #DADADA", p: 2, flexDirection: { md: "row", xs: "column" } }}>
                <PhonelinkRingIcon sx={{ fontSize: 50, mr: { md: 3 } }} />

                <Box component="div">
                  <Typography className="fst-italic" component="h4" variant="h4" sx={{ mb: { md: 0.5, xs: 2 }, mt: { md: 0, xs: 2 }, textAlign: { md: "left", xs: "center" } }}>
                    Send Text Message
                  </Typography>
                  <Typography component="p" variant="body" className="text-md-start text-center" sx={{ color: "#858585" }}>
                    Send a confirmation text message in order to verify your personal information
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item md={11} sm={12} xs={12} sx={{ mx: "auto" }}>
              <Box component="div" className="text-center">
                <Link to="/#" color="secondary" variant="h4" sx={{ textDecoration: "none" }}>
                  Skip For Now
                </Link>
              </Box>
            </Grid>

            <Grid item md={11} sm={12} xs={12} sx={{ mx: "auto" }}>
              <Box component="div" className="text-end" sx={{ mt: { md: 32, xs: 3 } }} onClick={() => { if (typeof handleNext === "function") { handleNext(); } }}>
                <Button color="secondary" variant="contained">Continue</Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container >
    );
  }
}

export default injectIntl(withRouter(Verification));