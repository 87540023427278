import React, { Component } from "react";
import { withRouter } from "../../components/hoc/withRouter";
import { Typography } from "@mui/material";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import HdrAutoIcon from "@mui/icons-material/HdrAuto";
import AvatarGroup from "@mui/material/AvatarGroup";
import ImageIcon from "@mui/icons-material/Image";
import Container from "@mui/material/Container";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import StepRestaurants from "./components/StepRestaurants";
import StepPostDetails from "./components/StepPostDetails";
import StepFinalEdits from "./components/StepFinalEdits";
import StepCampaign from "./components/StepCampaign";
import StepContents from "./components/StepContents";

import "../../styles/css/components/class.css";
import "../../styles/css/pages/campaign.css";

class CreatePost extends Component {

  constructor(props) {
    super(props);

    this.steps = ["Restaurants", "Campaign", "Post Detail", "Content", "Final Edits"];

    this.state = {
      activeStep: 0,
      errors: {},
      totalSteps: 3,
      //completedSteps: 1,
    };
  }

  /*isLastStep = () => {
    const { activeStep, totalSteps } = this.state;

    return (activeStep === (totalSteps - 1));
  };

  allStepsCompleted = () => {
    const { activeStep, totalSteps } = this.state;

    return completedSteps() === totalSteps();
  };*/

  handleNext = () => {
    const { activeStep } = this.state;
    /*const newActiveStep =
      this.isLastStep() && !this.allStepsCompleted()
        ? // It"s the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;*/

    this.setState({ activeStep: ((activeStep || 0) + 1) })
  }

  schedulePost = () => {
    
  }

  handleBack = () => {
    const { activeStep } = this.state;

    this.setState({ activeStep: ((activeStep || 0) - 1) })
  };

  render() {
    const { activeStep } = this.state;

    return (
      <>
        <Container component="div" sx={{ maxWidth: "100% !important", mt: 5, backgroundColor: "#ffffff" }}>
          <Grid>
            <Grid item md={8} sm={12} xs={12}>
              <Box className="stepper-wrap w-100">
                <Stepper nonLinear activeStep={(activeStep || 0)} sx={{ mb: 3 }}>
                  {this.steps.map((label, index) => (
                    <Step key={label} completed={false} /*completed={completed[index]}*/>
                      <div onClick={() => this.setState({ activeStep: index })}>

                        <StepLabel
                          optional={
                            <>
                              {(index === 0) && (
                                <div className="campaign-restaurants-label text-center">
                                  {/* <Avatar alt="Remy Sharp" src={require("../../assets/images/monogram01.png")} /> */}
                                  <AvatarGroup max={3} className="justify-content-center">
                                    <Avatar alt="Remy Sharp" src={require("../../assets/images/monogram01.png")} sx={{ margin: "0 !important" }} />
                                    <Avatar alt="Travis Howard" src={require("../../assets/images/monogram02.png")} sx={{ margin: "0 !important" }} />
                                    <Avatar alt="Cindy Baker" src={require("../../assets/images/monogram01.png")} sx={{ margin: "0 !important" }} />
                                    <Avatar alt="Agnes Walker" src={require("../../assets/images/monogram01.png")} sx={{ margin: "0 !important" }} />
                                  </AvatarGroup>
                                </div>
                              )}
                              {(index === 1) && (
                                <div className="campaign-name-label">Christmas</div>
                              )}
                              {(index === 2) && (
                                <div className="campaign-post-label">
                                  <Stack direction="row" spacing={1} className="align-items-center">
                                    {/* <AvatarGroup max={3} className="justify-content-center"> */}
                                    <Avatar variant="square" alt="Remy Sharp" src={require("../../assets/images/logo-instagram.png")} sx={{ width: 20, height: 20 }} />
                                    <Avatar variant="square" alt="Travis Howard" src={require("../../assets/images/logo-facebook.png")} sx={{ width: 20, height: 20 }} />
                                    {/* </AvatarGroup> */}
                                    <Typography component="p" className="fst-italic">5/16/2022 5pm</Typography>
                                  </Stack>
                                </div>
                              )}
                              {(index === 3) && (
                                <div className="campaign-content-label text-center">
                                  <Stack direction="row" spacing={0.7} className="justify-content-center">
                                    <ImageIcon color="primary" />
                                    <HdrAutoIcon color="primary" />
                                  </Stack>
                                </div>
                              )}
                            </>
                          }
                        >
                          <span>{label}</span><span><KeyboardArrowRightIcon /></span>
                        </StepLabel>
                      </div>
                    </Step>
                  ))}
                </Stepper>

                {((activeStep || 0) === 0) && (
                  <StepRestaurants />
                )}

                {((activeStep || 0) === 1) && (
                  <StepCampaign />
                )}

                {((activeStep || 0) === 2) && (
                  <StepPostDetails />
                )}

                {((activeStep || 0) === 3) && (
                  <StepContents />
                )}

                {((activeStep || 0) === 4) && (
                  <StepFinalEdits />
                )}
                <div>
                  <React.Fragment>
                    <Box sx={{ display: "flex", flexDirection: { sm: "row", xs: "column" }, gap: 1, pt: 2 }}>
                      <Button
                        color="inherit" variant="contained"
                        disabled={(activeStep || 0) === 0}
                        onClick={() => this.handleBack()}>
                        Back
                      </Button>
                      <Box sx={{ flex: "1 1 auto" }} />

                      {((activeStep || 0) < (this.steps.length - 1)) && (
                        <Button color="secondary" variant="contained" onClick={() => this.handleNext()}>
                          Next step
                        </Button>
                      )}
                      {((activeStep || 0) === (this.steps.length - 1)) && (
                        <Button color="secondary" variant="contained" onClick={() => this.schedulePost()}>
                          Schedule Post
                        </Button>
                      )}
                    </Box>
                  </React.Fragment>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }
}

export default withRouter(CreatePost);