import React, { Component } from "react";
import { injectIntl } from "react-intl";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CssBaseline from "@mui/material/CssBaseline";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import DoneIcon from "@mui/icons-material/Done";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Select from "@mui/material/Select";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";

const handleClick = () => {
  console.log("You clicked the Chip.");
};

const handleDelete = () => {
  console.log("You clicked the delete icon.");
};

class StepPostDetails extends Component {

  /*constructor(props) {
    super(props);
  }*/

  render() {

    return (
      <Container component="main" sx={{ maxWidth: "100% !important", padding: "0 !important" }}>
        <CssBaseline />
        <Grid item xs={12}>
          <Divider sx={{ borderColor: "#C6C6C6", pt: 0, mb: 3 }} />
          <Box>
            <Grid container>
              <Grid item sm={9} sx={{ mx: "auto" }}>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Grid container spacing={2}>
                    <Grid item md={4} xs={12}>
                      <Typography component="p" variant="h4" sx={{ fontWeight: "500" }}>
                        Choose a Date
                      </Typography>
                      <FormControl variant="standard" sx={{ minWidth: 120, }}>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          className="Mui-selectduration"
                          value={10}
                          onChange={() => { }}
                          sx={{ borderBottom: 0, fontStyle: "italic", height: 58, fontWeight: "300", fontSize: 18, color: "#000000" }}
                        >
                          <MenuItem value={10}>5/16/2022</MenuItem>
                          <MenuItem value={20}>5/16/2022</MenuItem>
                          <MenuItem value={30}>5/16/2022</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item md={4} xs={12}>
                      <Typography component="p" variant="h4" sx={{ fontWeight: "500" }}>
                        Choose a Time
                      </Typography>
                      <FormControl variant="standard" sx={{ minWidth: 120, }}>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          className="Mui-selectduration"
                          value={10}
                          onChange={() => { }}
                          sx={{ borderBottom: 0, fontStyle: "italic", height: 58, fontWeight: "300", fontSize: 18, color: "#000000" }}
                        >
                          <MenuItem value={10}>5:00 PM</MenuItem>
                          <MenuItem value={20}>5:00 PM</MenuItem>
                          <MenuItem value={30}>5:00 PM</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item md={4} xs={12}>
                      <Typography component="p" variant="h4" sx={{ fontWeight: "500" }}>
                        Choose a Frequency
                      </Typography>
                      <FormControl variant="standard" sx={{ minWidth: 120, }}>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          className="Mui-selectduration"
                          value={10}
                          onChange={() => { }}
                          sx={{ borderBottom: 0, fontStyle: "italic", height: 58, fontWeight: "300", fontSize: 18, color: "#000000" }}
                        >
                          <MenuItem value={10}>Every Tuesday</MenuItem>
                          <MenuItem value={20}>Every Thursday</MenuItem>
                          <MenuItem value={30}>Every Friday</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Divider sx={{ borderColor: "#C6C6C6", pt: 2, mb: 3 }} />

            <Grid container>
              <Grid item sm={9} sx={{ mx: "auto" }}>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                      <Typography component="p" variant="h4" sx={{ fontWeight: "500" }}>
                        Choose a Platform
                      </Typography>

                      <Box sx={{ mt: 2.5, display: "flex", flexWrap: "wrap", gap: 1.6, pl: { sm: 2.8, xs: 0 } }}>
                        <Chip
                          className="Mui-chipsquare"
                          label="Facebook"
                          color="secondary"
                          avatar={<Avatar src={require("../../../assets/images/logo-facebook.png")}
                            sx={{ width: "auto", height: 20, borderRadius: 0 }} />}
                        />

                        <Chip
                          className="Mui-chipsquare active"
                          label="Instagram"
                          color="secondary"
                          deleteIcon={<DoneIcon sx={{ color: "#000000 !important" }} />}
                          onClick={handleClick}
                          onDelete={handleDelete}
                          avatar={<Avatar src={require("../../../assets/images/logo-instagram.png")}
                            sx={{ width: "auto", height: 20, borderRadius: 0 }} />}
                        />

                        <Chip
                          className="Mui-chipsquare active"
                          label="Twitter"
                          color="secondary"
                          deleteIcon={<DoneIcon sx={{ color: "#000000 !important" }} />}
                          onClick={handleClick}
                          onDelete={handleDelete}
                          avatar={<Avatar src={require("../../../assets/images/logo-twitter.png")}
                            sx={{ width: "auto", height: 16, borderRadius: 0 }} />}
                        />

                        <Chip
                          className="Mui-chipsquare"
                          label="Email"
                          color="secondary"
                          avatar={<Avatar src={require("../../../assets/images/logo-gmail.png")}
                            sx={{ width: "auto", height: 20, borderRadius: 0 }} />}
                        />

                        <Chip
                          className="Mui-chipsquare"
                          label="Text"
                          color="secondary"
                          avatar={<Avatar src={require("../../../assets/images/logo-text.png")}
                            sx={{ width: "auto", height: 20, borderRadius: 0 }} />}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Divider sx={{ borderColor: "#C6C6C6", pt: 2, mb: 3 }} />

            <Grid container>
              <Grid item sm={9} xs={12} sx={{ mx: "auto" }}>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                      <Typography component="p" variant="h4" sx={{ fontWeight: "500", color: "#9F9F9F", mb: 2 }}>
                        Choose list
                      </Typography>

                      <Box component="div" sx={{ display: "flex", alignItems: "center", pl: 4, opacity: 0.5 }}>
                        <Avatar sx={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", mr: 2, height: 30, width: 30 }} alt="Resturent" src={require("../../../assets/images/monogram01.png")} />

                        <FormControl variant="standard" sx={{ minWidth: 120, height: 50 }}>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            className="Mui-selectduration"
                            value={10}
                            onChange={() => { }}
                            sx={{ borderBottom: 0, fontStyle: "italic", height: 58, fontWeight: "300", fontSize: 18, color: "#9F9F9F" }}
                          >
                            <MenuItem value={10}>Contact List</MenuItem>
                            <MenuItem value={20}>Contact List</MenuItem>
                            <MenuItem value={30}>Contact List</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>

                      <Box component="div" sx={{ display: "flex", alignItems: "center", pl: 4, opacity: 0.5 }}>
                        <Avatar sx={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", mr: 2, height: 30, width: 30 }} alt="Resturent" src={require("../../../assets/images/monogram01.png")} />

                        <FormControl variant="standard" sx={{ minWidth: 120, height: 50 }}>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            className="Mui-selectduration"
                            value={10}
                            onChange={() => { }}
                            sx={{ borderBottom: 0, fontStyle: "italic", height: 58, fontWeight: "300", fontSize: 18, color: "#9F9F9F" }}
                          >
                            <MenuItem value={10}>Contact List</MenuItem>
                            <MenuItem value={20}>Contact List</MenuItem>
                            <MenuItem value={30}>Contact List</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Box >
        </Grid >
      </Container >
    );
  }
}

export default (injectIntl(StepPostDetails));