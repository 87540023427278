import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { getDeviceMode } from "../helpers/utils";
import { DEFAULT_LANGUAGE, sidebarMenuConfig, tmpConstant } from "../helpers/constants";
import configs from "../helpers/config";

import actionTypes from "../actions";

const persistConfig = {
  key: `${configs.store_name}__language`,
  storage,
};

const loading = (state = false, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_APPLICATION_LOADING:
      return action.loading;

    default:
      return state;
  }
}

const language = (state = DEFAULT_LANGUAGE, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_LANGUAGE:
      return action.language;

    default:
      return state;
  }
}

const defaultConfig = {
  menu: sidebarMenuConfig,
}

const config = (state = defaultConfig, action) => {
  switch (action.type) {
    // case actionTypes.FETCH_APPLICATION_SUCCESS:
    //   return (action.payload.config || {});

    default:
      return state;
  }
}

const initialSize = {
  width: window.innerWidth,
  height: window.innerHeight,
  mode: getDeviceMode(window.innerWidth)
}

const viewport = (state = initialSize, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_VIEWPORT:
      return ({
        ...action.size,
        mode: getDeviceMode(action.size.width)
      });

    default:
      return state;
  }
}

const languages = combineReducers({
  defaultLang: language
});

const constants = (state = tmpConstant, action) => {
  switch (action.type) {
    case actionTypes.FETCH_APPLICATION_SUCCESS:
      // return action.payload.constants || {};
      return state;

    default:
      return state;
  }
}

const appActiveMenu = (state = "", action) => {
  switch (action.type) {
    case actionTypes.SET_ACTIVE_MENU:
      return (action.payload || "");

    default:
      return state;
  }
}

const hurmburgMenu = (state = false, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_HUMBURG_MENU:
      return (action.payload || false);

    default:
      return state;
  }
}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  application: combineReducers({
    loading,
    language: persistReducer(persistConfig, languages),
    config,
    viewport,
    constants,
    appActiveMenu,
    hurmburgMenu
  })
}
