/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {

  // Page Titles
  "dt.login": "Dining Tek: DiningTek is a Restaurant Marketing and Sales Partner. DiningTek empowers Restaurants to Grow their Business",
  "dt.forgot_password": "Forgot Password: Dining Tek",
  "dt.reset_password": "Reset Password: Dining Tek",
  "dt.generic_page.comming_soon": "Comming soon: Dining Tek",
  "dt.generic_page.not_found": "Page not found: Dining Tek",
  "dt.home": "Home: Dining Tek",
  "dt.create_campaign": "Create Campaign: Dining Tek",

  // Common texts
  "email": "email",
  "username": "username",
  "password": "password",
  "new_password": "new password",
  "old_password": "old password",
  "confirm_password": "confirm password",
  "privacy_policy": "privacy policy",
  "terms_and_conditions": "Terms & Condition",
  "change_password": "change password",
  "logout": "Logout",
  "client": "client",
  "client_name": "client name",
  "clients": "clients",
  "campaign": "campaign",
  "campaigns": "campaigns",
  "start_date": "start date",
  "end_date": "end date",
  "action": "action",
  "empty": "empty",
  "company": "company",
  "name": "name",
  "job_title": "job title",
  "telephone": "telephone",
  "date": "date",
  "column": "column",
  "value": "value",
  "edit": "edit",
  "update": "update",
  "link": "link",
  "close": "close",
  "delete": "delete",
  "filter": "filter",
  "reset": "reset",
  "complete": "complete",
  "results": "results",
  "type": "type",
  "select": "select",
  "coming_soon": "coming soon",
  "under_constuction": "This page is under construction.",
  "campaign_name": "campaign name",
  "uploaded_files": "Uploaded files",
  "contact": "contact",
  "or": "or",

  // Common labels
  "label.select_field": "select {field}",
  "label.yes": "yes",
  "label.no": "no",

  // Modal
  "modal.confirm": "confirm",
  "modal.alert": "alert !",

  // Record count display
  "showing_records_of_total": "showing {records} out of {total}",

  //Menu
  "menu.home": "Home",
  "menu.manageRestaurants": "Manage Restaurants",
  "menu.uploadImages": "Upload Images",
  "menu.imageLibrary": "Image Library",
  "menu.textLibrary": "Text Library",
  "menu.createPost": "Create Post",
  "menu.createCampaign": "Create Campaign",
  "menu.marketingCalender": "Marketing Calender",
  "menu.subscriptions": "Subscriptions",
  "menu.analytics": "Analytics",
  "menu.contactDiningTek": "Contact Dining Tek",
  "menu.settings": "Settings",

  // error validation messages
  "error.required": "{field} is required.",
  "error.are_required": "{field} are required.",
  "error.select": "Please select {field}.",
  "error.select_atleast_one": "Please select at least one {field}.",
  "error.enter": "Please enter {field}.",
  "error.valid": "Please enter valid {field}.",
  "error.invalid": "Invalid {field}.",
  "error.password_not_match": "Confirm password does not match.",
  "error.password_min_char": "Password should contains minimum 8 characters.",
  "error.password_valid_char": "Password should contain at least 3 of a-z or A-Z and a number and a special character.",
  "error.exact_number_field": "Please enter {value} number {field}.",
  "error.less_than_value": "{field} should be less than {value}.",
  "error.atleast_value": "{field} should be atleast {value}.",
  "error.something_error": "There is some error inside the fields.",
  "error.less_than_value_character": "{field} should be less than {value} characters.",
  "error.after_start_date": "End date field should be after start date",
  "error.after_start_time": "End time should be after start time",
  "error.after_today": "End date should be today or future date.",
  "error.start_after_today": "Start date should be today or future date.",
  "error.date_after_today": "Date should be today or future date.",
  "error.greatereq_than_value": "{field} should be greater than or equal to {value}.",
  "error.image_upload": "Image not uploaded successfully",
  "error.after_date": "{field} should be greater than or equal to current date",
  "error.any_one_option_required": "Anyone option should be enable for {field}",
  "error.not_matched_with_val": "{field} not matched with {value}",
  "error.please_remove_uploaded_file": "Please remove uploaded file",

  // Success message
  "success.updated_successfully": "{field} updated successfully.",
  "success.lead_status_updated_success": "Lead {status} successfully",

  //Placeholder
  "placeholder.select_field": "Select {field}",
  "placeholder.enter_field": "Enter {field}",
  "placeholder.please_enter_field": "Please enter {field}",
  "placeholder.fix_field": "Fix {field}",
  "placeholder.search": "search",

  // Message
  "message.no_found_data": "no data found",
  "message.this_action_will_reset_all_your_form_data": "Note: This action will reset all your form data",

  // Confirm message
  "confirm.are_you_sure": "Are you sure want to {field}?",
  "confirm.are_you_sure_delete": "Are you sure want to delete {field}?",
  "confirm.are_you_sure_want_to_change_upload_type": "Are you sure want to change upload type?",

  // Buttons
  "btn.cancel": "cancel",
  "btn.submit": "submit",
  "btn.update": "update",
  "btn.reset": "reset",
  "btn.next": "next",
  "btn.back": "back",
  "btn.skip": "skip",
  "btn.save": "save",
  "btn.ok": "ok",
  "btn.reject": "reject",
  "btn.delete": "delete",
  "btn.clone": "clone",
  "btn.yes": "yes",
  "btn.no": "no",
  "btn.continue": "continue",

  //sign-in page
  "login.title": "Sign In",
  "login.sub_title": "Login with your username and password below.",
  "login.btn_submit": "Login",
  "login.keep_me_logged_in": "Keep me logged in",
  "login.warning_text": "Please be aware that the unauthorised access to systems or data may result in discipliary action.",
  "login.forgot_link": "forgot password?",
  "dt.login.tab.diner": "Diner",
  "dt.login.tab.restaurant_owner": "Restaurant Owner",
  "dt.login.tab.market_agency": "Market Agency",

  //Forgot Password Page
  "forgot_password.title": "forgot password",
  "forgot_password.sub_title": "enter email for reset your password.",
  "forgot_password.login_text": "back to login",

  //Reset Password Page
  "reset_password.title": "reset password",
  "reset_password.sub_title": "enter new password below.",
  "reset_password.show_password": "show password",
  "reset_password.hide_password": "hide password",

  //Generic page
  "generic_page.coming_soon.title": "coming soon",
  "generic_page.coming_soon.sub_title": "This page is under construction.",
  "generic_page.not_found.title": "not found",
  "generic_page.not_found.sub_title": "The requested page is not found.",

  //Home page
  "home.title": "Home",
  "dt.home.tab.upcoming_post": "Upcoming Post",
  "dt.home.tab.previous_post": "Previous Post",

  //Welcom page
  "welcome.title": "Welcome to Dining Tek",
  "subscription.title": "Choose a Subscription Plan",
  "checkout.title": "Checkout",
  "cart.title": "Cart",
  "cart.success": "Success!",
  "restaurant.details.title": "Enter Restaurant Details",
  "verification.title": "Verification",
  "getstart.title": "Let's Get Started!",

  //Create Campaign Page
  "create_campaign.title": "Create Campaign",
};