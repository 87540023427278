import React from "react";

export const ApplicationLoader = () => {
  return (
    <div className="loader_overlay">
      <div className="overlay__inner">
        <div className="overlay__content">
          
        </div>
      </div>
    </div>
  );
}
