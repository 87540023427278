import React, { Component } from "react";
import { injectIntl } from "react-intl";

import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
// import DialogContentText from "@mui/material/DialogContentText";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
// import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DownloadIcon from "@mui/icons-material/Download";
import CssBaseline from "@mui/material/CssBaseline";
import SearchIcon from "@mui/icons-material/Search";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import Checkbox from "@mui/material/Checkbox";
import TabContext from "@mui/lab/TabContext";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TabPanel from "@mui/lab/TabPanel";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Tabs from "@mui/material/Tabs";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";

class StepContents extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activeTab: "imagevideolibrary",
      open: false,
      maxWidth: "lg",
    };
  }

  render() {
    const { activeTab, open, maxWidth } = this.state;

    const label = { inputProps: { "aria-label": "Select Images or Videos" } };

    const handleClose = () => {
      this.setState({ open: false });
    };

    return (
      <Container component="main" sx={{ maxWidth: "100% !important", padding: "0 !important" }}>
        <CssBaseline />
        <Grid item xs={12}>
          <Divider sx={{ borderColor: "#C6C6C6", pt: 0, mb: 3 }} />

          <Box>
            <Grid container>
              <Grid item sm={7} sx={{ mx: "auto" }}>
                <Box component="div" sx={{ mb: 2 }}>
                  <Grid container justifyContent="space-between" spacing={2}>
                    <Grid item md={6} xs={12}>
                      <Typography component="p" variant="h4" sx={{ fontWeight: "500" }}>
                        Images / Videos
                      </Typography>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Box sx={{ textAlign: "right" }}>
                        <Link to="/#" color="secondary" sx={{ textDecoration: "none" }} onClick={() => this.setState({ open: true })}>
                          Open Image / Video Library
                        </Link>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box component="div">
                  <Grid container spacing={3}>
                    <Grid item md={4} xs={12}>
                      <Box component="div" sx={{ mb: 3 }}>
                        <Box component="div" sx={{ position: "relative" }}>
                          <img
                            src={require("../../../assets/images/post-01.png")}
                            alt=""
                            className="img-responsive"
                            loading="lazy"
                          />
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item md={4} xs={12}>
                      <Box component="div" sx={{ mb: 3 }}>
                        <Box component="div" sx={{ position: "relative" }}>
                          <img
                            src={require("../../../assets/images/post-02.png")}
                            alt=""
                            className="img-responsive"
                            loading="lazy"
                          />
                        </Box>
                      </Box>

                      <Box component="div" sx={{ mb: 3 }}>
                        <Box component="div" sx={{ position: "relative" }}>
                          <img
                            src={require("../../../assets/images/post-03.png")}
                            alt=""
                            className="img-responsive"
                            loading="lazy"
                          />
                          <DoneIcon color="secondary" sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%,-50%)",
                            height: "86px",
                            width: "67px",
                          }} />
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item md={4} xs={12}>
                      <Box component="div" sx={{ mb: 3 }}>
                        <Box component="div" sx={{ position: "relative" }}>
                          <img
                            src={require("../../../assets/images/post-04.png")}
                            alt=""
                            className="img-responsive"
                            loading="lazy"
                          />
                        </Box>
                      </Box>

                      <Box component="div" sx={{ mb: 3 }}>
                        <Box component="div" sx={{ position: "relative" }}>
                          <img
                            src={require("../../../assets/images/post-05.png")}
                            alt=""
                            className="img-responsive"
                            loading="lazy"
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box component="div">
                  <Grid container justifyContent="space-between" spacing={2}>
                    <Grid item md={12} xs={12}>
                      <Box>
                        <Link to="/#" color="primary" variant="h5" sx={{ display: "flex", alignItems: "center", textDecoration: "none" }}>
                          <AddToPhotosIcon sx={{ mr: 1 }} />
                          Add New Image
                        </Link>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Divider sx={{ borderColor: "#C6C6C6", pt: 2, mb: 3 }} />

            <Grid container>
              <Grid item sm={7} xs={12} sx={{ mx: "auto" }}>
                <Box component="div" sx={{ mb: 2 }}>
                  <Grid container justifyContent="space-between" spacing={2}>
                    <Grid item md={6} sm={6} xs={6}>
                      <Typography component="p" variant="h4" sx={{ fontWeight: "500" }}>
                        Text
                      </Typography>
                    </Grid>

                    <Grid item md={6} sm={6} xs={6}>
                      <Box sx={{ textAlign: "right" }}>
                        <Link to="/#" color="secondary" sx={{ textDecoration: "none" }}>
                          Open Text Library
                        </Link>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box component="div">
                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sx={{ margin: "0 auto" }}>
                      <Box component="div" className="active" sx={{ display: "flex", alignItems: "center", marginBottom: 1.5, border: "2px solid #FFC600", borderRadius: 4, padding: 1.5 }}>
                        <Box component="p" sx={{ margin: 0, spacing: { fontSize: 16, sm: 18 } }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis </Box>
                        <DoneIcon color="secondary" sx={{ width: 50, height: 50 }} />
                      </Box>

                      <Box component="div" sx={{ display: "flex", alignItems: "center", marginBottom: 1.5, border: "1px solid #B5B5B5", borderRadius: 4, padding: 1.5 }}>
                        <Box component="p" sx={{ margin: 0, spacing: { fontSize: 16, sm: 18 } }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in </Box>
                      </Box>

                      <Box component="div" sx={{ display: "flex", alignItems: "center", marginBottom: 1.5, border: "1px solid #B5B5B5", borderRadius: 4, padding: 1.5 }}>
                        <Box component="p" sx={{ margin: 0, spacing: { fontSize: 16, sm: 18 } }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis </Box>
                      </Box>

                      <Box component="div" sx={{ display: "flex", alignItems: "center", marginBottom: 1.5, border: "1px solid #B5B5B5", borderRadius: 4, padding: 1.5 }}>
                        <Box component="p" sx={{ margin: 0, spacing: { fontSize: 16, sm: 18 } }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis </Box>
                      </Box>

                      <Box component="div" sx={{ display: "flex", alignItems: "center", marginBottom: 1.5, border: "1px solid #B5B5B5", borderRadius: 4, padding: 1.5 }}>
                        <Box component="p" sx={{ margin: 0, spacing: { fontSize: 16, sm: 18 } }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore orum.</Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box component="div">
                  <Grid container justifyContent="space-between" spacing={2}>
                    <Grid item md={12} xs={12}>
                      <Box sx={{ mt: 1 }}>
                        <Link to="/#" color="primary" variant="h5" sx={{ display: "flex", alignItems: "center", textDecoration: "none" }}>
                          <AddToPhotosIcon sx={{ mr: 1 }} />
                          Add New Text
                        </Link>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box >
        </Grid >

        <Dialog
          // fullScreen={fullScreen}
          fullWidth
          maxWidth={maxWidth}
          open={(open || false)}
          className="modal-content-upload"
        // onClose={() => this.handleClose()}
        >
          <DialogTitle>
            <Typography variant="div" component="p">
              <CloseIcon size="small" onClick={handleClose} />
            </Typography>
          </DialogTitle>

          <DialogContent sx={{ p: 4 }}>
            <Box component="div" sx={{ mb: 3 }}>
              {((activeTab || "imagevideolibrary") === "imagevideolibrary") && (
                <Typography variant="h4" component="h2" sx={{ fontWeight: "600" }}>Image / Video Library</Typography>
              )}

              {((activeTab || "textlibrary") === "textlibrary") && (
                <Typography variant="h4" component="h2" sx={{ fontWeight: "600" }}>Text Library</Typography>
              )}

              {((activeTab || "uploadimages") === "uploadimages") && (
                <Typography variant="h4" component="h2" sx={{ fontWeight: "600" }}>Upload Images</Typography>
              )}

              {((activeTab || "uploadvideos") === "uploadvideos") && (
                <Typography variant="h4" component="h2" sx={{ fontWeight: "600" }}>Upload Videos</Typography>
              )}

              {((activeTab || "uploadtext") === "uploadtext") && (
                <Typography variant="h4" component="h2" sx={{ fontWeight: "600" }}>Upload Text</Typography>
              )}
            </Box>

            <Box component="div">
              <TabContext value={(activeTab || "").toString()}>
                <Box sx={{ borderBottom: 1, borderColor: "#000000", marginBottom: 1.5 }}>
                  <Tabs value={(activeTab || "")} onChange={(e, value) => this.setState({ activeTab: (value || "imagevideolibrary") })}
                    variant="scrollable" scrollButtons={false}
                    TabIndicatorProps={{
                      style: { transition: "none", width: "auto" }
                    }}
                  >
                    <Tab
                      key="imagevideolibrary"
                      value="imagevideolibrary"
                      label={"Image / Video Library"}
                    />
                    <Tab
                      key="textlibrary"
                      value="textlibrary"
                      label={"Text Library"}
                    />
                    <Tab
                      key="uploadimages"
                      value="uploadimages"
                      label={"Upload Images"}
                    />
                    <Tab
                      key="uploadvideos"
                      value="uploadvideos"
                      label={"Upload Videos"}
                    />
                    <Tab
                      key="uploadtext"
                      value="uploadtext"
                      label={"Upload Text"}
                    />
                  </Tabs>
                </Box>

                <TabPanel value={(activeTab || "").toString()} sx={{ p: 0, pt: 2 }}>
                  {((activeTab || "imagevideolibrary") === "imagevideolibrary") && (
                    <Container component="div" sx={{ maxWidth: "100% !important", padding: "0 !important" }}>
                      <Grid item xs={12}>
                        <Box component="div" sx={{ mt: 1 }}>
                          <TextField id="searchkeywordtags" label="Search Keywords / Tags" variant="outlined"
                            InputProps={{
                              startAdornment: <SearchIcon color="primary" />
                            }}
                            sx={{ borderRadius: 40, boxShadow: "2px 6px 4px rgba(0, 0, 0, 0.25)", width: "100%" }} />
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Box component="div" sx={{ mt: 2.5 }}>
                          <Grid container spacing={2.5}>
                            <Grid item md={3} sm={4} xs={12}>
                              <img
                                src={require("../../../assets/images/post.png")}
                                alt=""
                                className="img-responsive"
                                loading="lazy"
                              />
                            </Grid>

                            <Grid item md={3} sm={4} xs={12}>
                              <img
                                src={require("../../../assets/images/post.png")}
                                alt=""
                                className="img-responsive"
                                loading="lazy"
                              />
                            </Grid>

                            <Grid item md={3} sm={4} xs={12}>
                              <img
                                src={require("../../../assets/images/post.png")}
                                alt=""
                                className="img-responsive"
                                loading="lazy"
                              />
                            </Grid>

                            <Grid item md={3} sm={4} xs={12}>
                              <img
                                src={require("../../../assets/images/post.png")}
                                alt=""
                                className="img-responsive"
                                loading="lazy"
                              />
                            </Grid>

                            <Grid item md={3} sm={4} xs={12}>
                              <img
                                src={require("../../../assets/images/post.png")}
                                alt=""
                                className="img-responsive"
                                loading="lazy"
                              />
                            </Grid>

                            <Grid item md={3} sm={4} xs={12}>
                              <img
                                src={require("../../../assets/images/post.png")}
                                alt=""
                                className="img-responsive"
                                loading="lazy"
                              />
                            </Grid>

                            <Grid item md={3} sm={4} xs={12}>
                              <img
                                src={require("../../../assets/images/post.png")}
                                alt=""
                                className="img-responsive"
                                loading="lazy"
                              />
                            </Grid>

                            <Grid item md={3} sm={4} xs={12}>
                              <img
                                src={require("../../../assets/images/post.png")}
                                alt=""
                                className="img-responsive"
                                loading="lazy"
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Container>
                  )}
                  {((activeTab || "textlibrary") === "textlibrary") && (
                    <Container component="div" sx={{ maxWidth: "100% !important", padding: "0 !important" }}>
                      <Grid item xs={12}>
                        <Box component="div" sx={{ mt: 1 }}>
                          <TextField id="searchkeyword" label="Search Keywords" variant="outlined"
                            InputProps={{
                              startAdornment: <SearchIcon color="primary" />
                            }}
                            sx={{ borderRadius: 40, boxShadow: "2px 6px 4px rgba(0, 0, 0, 0.25)", width: "100%" }} />
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Box component="div" sx={{ mt: 2.5 }}>
                          <Grid container spacing={2.5}>
                            <Grid item md={12} sm={12} xs={12}>
                              <Box component="div" className="active" sx={{ display: "flex", alignItems: "center", marginBottom: 1, border: "1px solid #B5B5B5", borderRadius: 4, padding: 1.5 }}>
                                <Box component="p" sx={{ margin: 0 }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis </Box>
                              </Box>
                            </Grid>

                            <Grid item md={12} sm={12} xs={12}>
                              <Box component="div" className="active" sx={{ display: "flex", alignItems: "center", marginBottom: 1, border: "1px solid #B5B5B5", borderRadius: 4, padding: 1.5 }}>
                                <Box component="p" sx={{ margin: 0 }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis </Box>
                              </Box>
                            </Grid>

                            <Grid item md={12} sm={12} xs={12}>
                              <Box component="div" className="active" sx={{ display: "flex", alignItems: "center", marginBottom: 1, border: "1px solid #B5B5B5", borderRadius: 4, padding: 1.5 }}>
                                <Box component="p" sx={{ margin: 0 }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis </Box>
                              </Box>
                            </Grid>

                            <Grid item md={12} sm={12} xs={12}>
                              <Box component="div" className="active" sx={{ display: "flex", alignItems: "center", marginBottom: 1, border: "1px solid #B5B5B5", borderRadius: 4, padding: 1.5 }}>
                                <Box component="p" sx={{ margin: 0 }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis </Box>
                              </Box>
                            </Grid>

                            <Grid item md={12} sm={12} xs={12}>
                              <Box component="div" className="active" sx={{ display: "flex", alignItems: "center", marginBottom: 1, border: "1px solid #B5B5B5", borderRadius: 4, padding: 1.5 }}>
                                <Box component="p" sx={{ margin: 0 }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis </Box>
                              </Box>
                            </Grid>

                            <Grid item md={12} sm={12} xs={12}>
                              <Box component="div" className="active" sx={{ display: "flex", alignItems: "center", marginBottom: 1, border: "1px solid #B5B5B5", borderRadius: 4, padding: 1.5 }}>
                                <Box component="p" sx={{ margin: 0 }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Container>
                  )}
                  {((activeTab || "uploadimages") === "uploadimages") && (
                    <Container component="div" sx={{ maxWidth: "100% !important", padding: "0 !important" }}>
                      <Grid item xs={12}>
                        <Box component="div" className="file-upload-wrap">
                          <Box component="div" className="file-upload-drag">
                            <DownloadIcon />

                            <Box component="div" className="text-center">
                              <Typography variant="h4" component="h3" sx={{ mb: 1 }}>Drag Photo Here <br />or</Typography>

                              <Button color="secondary" variant="contained">Select From Computer</Button>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Box component="div" className="file-upload-wrap" sx={{ mt: 3 }}>
                          <Box component="div" className="file-upload-drag">

                            <Grid container spacing={3}>
                              <Grid item md={3} sm={4} xs={12}>
                                <Box component="div" className="position-relative upload-checkbox-image">
                                  <Checkbox className="upload-checkbox-select" {...label} icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} />
                                  <img
                                    src={require("../../../assets/images/recent02.png")}
                                    alt=""
                                    className="img-responsive"
                                    loading="lazy"
                                  />
                                </Box>
                              </Grid>

                              <Grid item md={3} sm={4} xs={12}>
                                <Box component="div" className="position-relative upload-checkbox-image">
                                  <Checkbox className="upload-checkbox-select" {...label} icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} />
                                  <img
                                    src={require("../../../assets/images/recent02.png")}
                                    alt=""
                                    className="img-responsive"
                                    loading="lazy"
                                  />
                                </Box>
                              </Grid>

                              <Grid item md={3} sm={4} xs={12}>
                                <Box component="div" className="position-relative upload-checkbox-image">
                                  <Checkbox className="upload-checkbox-select" {...label} icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} />
                                  <img
                                    src={require("../../../assets/images/recent02.png")}
                                    alt=""
                                    className="img-responsive"
                                    loading="lazy"
                                  />
                                </Box>
                              </Grid>

                              <Grid item md={3} sm={4} xs={12}>
                                <Box component="div" className="position-relative upload-checkbox-image">
                                  <Checkbox className="upload-checkbox-select" {...label} icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} />
                                  <img
                                    src={require("../../../assets/images/recent02.png")}
                                    alt=""
                                    className="img-responsive"
                                    loading="lazy"
                                  />
                                </Box>
                              </Grid>

                              <Grid item md={3} sm={12} xs={12}>
                                <Box component="div" className="file-upload-seemore">
                                  <Typography variant="h5" component="p" sx={{ mb: 1 }}>Add More Images</Typography>
                                  <DriveFolderUploadIcon size="large" sx={{ my: 1 }} />
                                  <Button size="small" color="secondary" variant="contained" sx={{ fontSize: 14 }}>Select From Computer</Button>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>

                        <Box sx={{ mt: 3, display: "flex", flexWrap: "wrap", gap: 1.6 }}>
                          <Chip label="Add Tag" variant="outlined" color="primary" icon={<AddIcon />} />
                          <Chip label="Special" color="secondary" icon={<CloseIcon />} sx={{ borderColor: "transparent" }} />
                          <Chip label="Offer" color="secondary" icon={<CloseIcon />} sx={{ borderColor: "transparent" }} />
                        </Box>

                        <Box component="div" className="text-end" sx={{ my: 2.5 }}>
                          <Button color="secondary" variant="contained">Upload Images</Button>
                        </Box>

                        <Box component="div" sx={{ mt: 3 }}>
                          <Typography variant="h4" component="h4" className="fw-semibold" sx={{ mb: 3 }}>Recently Added</Typography>

                          <Grid container spacing={2}>
                            <Grid item md={10} sm={12} xs={12}>
                              <Grid container spacing={2}>
                                <Grid item md={3} sm={4} xs={12}>
                                  <img
                                    src={require("../../../assets/images/recent01.png")}
                                    alt=""
                                    className="img-responsive"
                                    loading="lazy"
                                  />
                                </Grid>

                                <Grid item md={3} sm={4} xs={12}>
                                  <img
                                    src={require("../../../assets/images/recent01.png")}
                                    alt=""
                                    className="img-responsive"
                                    loading="lazy"
                                  />
                                </Grid>

                                <Grid item md={3} sm={4} xs={12}>
                                  <img
                                    src={require("../../../assets/images/recent01.png")}
                                    alt=""
                                    className="img-responsive"
                                    loading="lazy"
                                  />
                                </Grid>

                                <Grid item md={3} sm={4} xs={12}>
                                  <img
                                    src={require("../../../assets/images/recent01.png")}
                                    alt=""
                                    className="img-responsive"
                                    loading="lazy"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item md={2} sm={12} xs={12}>
                              <Grid container spacing={2}>
                                <Grid item md={12} sm={12} xs={12}>
                                  <Box component="div" className="file-upload-seemore">
                                    <Typography variant="h5" component="p" sx={{ mb: 1 }}>see more</Typography>
                                    <TrendingFlatIcon size="large" />
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Container>
                  )}
                  {((activeTab || "uploadvideos") === "uploadvideos") && (
                    <Container component="div" sx={{ maxWidth: "100% !important", padding: "0 !important" }}>
                      <Box component="div">
                        <Typography variant="h4" component="h4" className="fw-semibold" sx={{ mb: 2 }}>Link Youtube Video</Typography>
                        <TextField id="youtubevideo" label="https://www.youtube.com/" variant="outlined" sx={{ width: "100%" }} />
                      </Box>

                      <Box component="div" sx={{ mt: 3 }}>
                        <Typography variant="h4" component="h4" className="fw-semibold" sx={{ mb: 3 }}>Upload Videos</Typography>
                      </Box>

                      <Grid item xs={12}>
                        <Box component="div" className="file-upload-wrap">
                          <Box component="div" className="file-upload-drag">
                            <Grid container spacing={3}>
                              <Grid item md={3} sm={4} xs={12}>
                                <Box component="div" className="position-relative upload-checkbox-video">
                                  <Checkbox className="upload-checkbox-select" {...label} icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} />
                                  <img
                                    src={require("../../../assets/images/recent02.png")}
                                    alt=""
                                    className="img-responsive"
                                    loading="lazy"
                                  />
                                  <PlayCircleOutlineIcon className="icon-play" />
                                </Box>
                              </Grid>

                              <Grid item md={3} sm={4} xs={12}>
                                <Box component="div" className="position-relative upload-checkbox-video">
                                  <Checkbox className="upload-checkbox-select" {...label} icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} />
                                  <img
                                    src={require("../../../assets/images/recent02.png")}
                                    alt=""
                                    className="img-responsive"
                                    loading="lazy"
                                  />
                                  <PlayCircleOutlineIcon className="icon-play" />
                                </Box>
                              </Grid>

                              <Grid item md={3} sm={4} xs={12}>
                                <Box component="div" className="position-relative upload-checkbox-video">
                                  <Checkbox className="upload-checkbox-select" {...label} icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} />
                                  <img
                                    src={require("../../../assets/images/recent02.png")}
                                    alt=""
                                    className="img-responsive"
                                    loading="lazy"
                                  />
                                  <PlayCircleOutlineIcon className="icon-play" />
                                </Box>
                              </Grid>

                              <Grid item md={3} sm={4} xs={12}>
                                <Box component="div" className="position-relative upload-checkbox-video">
                                  <Checkbox className="upload-checkbox-select" {...label} icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} />
                                  <img
                                    src={require("../../../assets/images/recent02.png")}
                                    alt=""
                                    className="img-responsive"
                                    loading="lazy"
                                  />
                                  <PlayCircleOutlineIcon className="icon-play" />
                                </Box>
                              </Grid>

                              <Grid item md={3} sm={12} xs={12}>
                                <Box component="div" className="file-upload-seemore">
                                  <Typography variant="h5" component="p" sx={{ mb: 1 }}>Add More Videos</Typography>
                                  <DriveFolderUploadIcon size="large" sx={{ my: 1 }} />
                                  <Button size="small" color="secondary" variant="contained" sx={{ fontSize: 14 }}>Select From Computer</Button>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>

                        <Box sx={{ mt: 3, display: "flex", flexWrap: "wrap", gap: 1.6 }}>
                          <Chip label="Add Tag" variant="outlined" color="primary" icon={<AddIcon />} />
                          <Chip label="Special" color="secondary" icon={<CloseIcon />} sx={{ borderColor: "transparent" }} />
                          <Chip label="Offer" color="secondary" icon={<CloseIcon />} sx={{ borderColor: "transparent" }} />
                        </Box>

                        <Box component="div" className="text-end" sx={{ my: 2.5 }}>
                          <Button color="secondary" variant="contained">Upload Videos</Button>
                        </Box>

                        <Box component="div" sx={{ mt: 3 }}>
                          <Typography variant="h4" component="h4" className="fw-semibold" sx={{ mb: 3 }}>Recently Added</Typography>

                          <Grid container spacing={2}>
                            <Grid item md={10} sm={12} xs={12}>
                              <Grid container spacing={2}>
                                <Grid item md={3} sm={4} xs={12}>
                                  <img
                                    src={require("../../../assets/images/recent01.png")}
                                    alt=""
                                    className="img-responsive"
                                    loading="lazy"
                                  />
                                </Grid>

                                <Grid item md={3} sm={4} xs={12}>
                                  <img
                                    src={require("../../../assets/images/recent01.png")}
                                    alt=""
                                    className="img-responsive"
                                    loading="lazy"
                                  />
                                </Grid>

                                <Grid item md={3} sm={4} xs={12}>
                                  <img
                                    src={require("../../../assets/images/recent01.png")}
                                    alt=""
                                    className="img-responsive"
                                    loading="lazy"
                                  />
                                </Grid>

                                <Grid item md={3} sm={4} xs={12}>
                                  <img
                                    src={require("../../../assets/images/recent01.png")}
                                    alt=""
                                    className="img-responsive"
                                    loading="lazy"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item md={2} sm={12} xs={12}>
                              <Grid container spacing={2}>
                                <Grid item md={12} sm={12} xs={12}>
                                  <Box component="div" className="file-upload-seemore">
                                    <Typography variant="h5" component="p" sx={{ mb: 1 }}>see more</Typography>
                                    <TrendingFlatIcon size="large" />
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Container>
                  )}
                  {((activeTab || "uploadtext") === "uploadtext") && (
                    <Container component="div" sx={{ maxWidth: "100% !important", padding: "0 !important" }}>
                      <Grid item xs={12}>
                        <Box component="div">
                          <Typography variant="h4" component="h4" className="fw-semibold" sx={{ mb: 3 }}>Enter Text</Typography>
                          <TextField label="" className="w-100" multiline rows={4} defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis ipiscing elit, sed do eiusmod."
                          />
                        </Box>

                        <Box component="div">
                          <Typography variant="h4" component="h4" className="fw-semibold" sx={{ my: 3 }}>Upload Excel File</Typography>

                          <Box component="div" className="file-upload-wrap file-upload-excel-wrap">
                            <Box component="div" className="file-upload-drag">
                              <Box component="img" className="img-fluid icon-excel" src={require("../../../assets/images/icon-excel.png")} />

                              <Box component="div" className="text-center">
                                <Typography variant="h4" component="h3" sx={{ mb: 1 }}>Drag File Here <br />or</Typography>

                                <Button color="secondary" variant="contained">Select From Computer</Button>
                              </Box>
                            </Box>
                          </Box>

                          <Box component="div" className="text-end" sx={{ mt: 5 }}>
                            <Button color="secondary" variant="contained">Upload Text</Button>
                          </Box>
                        </Box>
                      </Grid>
                    </Container>
                  )}
                </TabPanel>
              </TabContext>
            </Box>
          </DialogContent>
          {/* <DialogActions>
            <Button autoFocus>
              Disagree
            </Button>
            <Button autoFocus>
              Agree
            </Button>
          </DialogActions> */}
        </Dialog >
      </Container >
    );
  }
}

export default (injectIntl(StepContents));