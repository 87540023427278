import React, { Component, Suspense } from "react";

import { withRouter } from "../../components/hoc/withRouter";

import Container from "@mui/material/Container";

import SubscriptionPlan from "./components/SubscriptionPlan";
import SuccessMessage from "./components/SuccessMessage";
import Checkout from "./components/Checkout";
import Welcome from "./components/Welcome";
import RestaurantDetails from "./components/RestaurantDetails";
import Verification from "./components/Verification";
import GetStarted from "./components/GetStarted";

import "../../styles/css/components/class.css";
import "../../styles/css/pages/campaign.css";

// constant for getting the component dynamically.
const components = {
  subscriptionPlan: SubscriptionPlan,
  successMessage: SuccessMessage,
  checkout: Checkout,
  welcome: Welcome,
  restaurantDetails: RestaurantDetails,
  verification: Verification,
  getStarted: GetStarted,
}

class MarketingAutomationSetup extends Component {

  constructor(props) {
    super(props);

    this.steps = [
      {
        "name": "welcome",
        "key": "welcome",
        "currentStep": 1,
        "component": "welcome",
      },
      {
        "name": "subscriptionPlan",
        "key": "subscriptionPlan",
        "currentStep": 2,
        "component": "subscriptionPlan",
      },
      {
        "name": "checkout",
        "key": "checkout",
        "currentStep": 3,
        "component": "checkout",
      },
      {
        "name": "successMessage",
        "key": "successMessage",
        "currentStep": 4,
        "component": "successMessage",
      },
      {
        "name": "restaurantDetails",
        "key": "restaurantDetails",
        "currentStep": 5,
        "component": "restaurantDetails",
      },
      {
        "name": "verification",
        "key": "verification",
        "currentStep": 6,
        "component": "verification",
      },
      {
        "name": "getStarted",
        "key": "getStarted",
        "currentStep": 7,
        "component": "getStarted",
      },
    ];

    this.state = {
      currentStep: 1,
      totalStep: 7
    };
  }

  _handleNext = () => {
    const { currentStep, totalStep } = this.state;

    if (totalStep > currentStep) {
      this.setState({ currentStep: currentStep + 1 });
    }
  }

  render() {
    const { currentStep } = this.state;

    return (
      <>
        <Container component="main" sx={{ maxWidth: "100% !important" }}>

          {(this.steps || []).filter((x) => x.currentStep === currentStep).map(({ key, component, currentStep: stepCurrentStepNo }) => {
            const TabComponent = components[component];

            return (
              <div key={key}>
                <Suspense fallback={(<></>)}>
                  {(currentStep === stepCurrentStepNo && typeof TabComponent !== "undefined" && TabComponent !== "") && (
                    <TabComponent
                      keyVal={key}
                      handleNext={() => this._handleNext()}
                    />
                  )}
                </Suspense>
              </div>
            );
          })}

        </Container>
      </>
    );
  }
}

export default withRouter(MarketingAutomationSetup);