import React, { Component } from "react";
import { injectIntl } from "react-intl";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import InputAdornment from "@mui/material/InputAdornment";
import CssBaseline from "@mui/material/CssBaseline";
import SearchIcon from "@mui/icons-material/Search";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import Divider from "@mui/material/Divider";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";

class StepCampaign extends Component {

  /*constructor(props) {
    super(props);
  }*/

  render() {

    return (
      <Container component="main" sx={{ maxWidth: "100% !important", padding: "0 !important" }}>
        <CssBaseline />
        <Grid item xs={12}>
          <Divider sx={{ borderColor: "#C6C6C6", pt: 0, mb: 3 }} />
          <Box>
            <Box component="div">
              <Typography component="h3" variant="h3" fontWeight="600" sx={{ mb: 2 }}>
                Choose Previous Campaign
              </Typography>

              <Grid container>
                <Grid item sm={9} xs={12} sx={{ mx: "auto" }}>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="previousCampaign"
                    label="Enter campaign name or topic"
                    name="previousCampaign"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    sx={{ borderRadius: 40, boxShadow: "2px 6px 4px rgba(0, 0, 0, 0.25)" }}
                  />
                </Grid>
              </Grid>

              <Divider sx={{ borderColor: "#C6C6C6", pt: 3, mb: 3 }} />
            </Box>

            <Box component="div">
              <Typography component="h3" variant="h3" fontWeight="600" sx={{ mb: 3 }}>
                Create New Campaign
              </Typography>

              <Grid container>
                <Grid item sm={9} sx={{ mx: "auto" }}>
                  <Box sx={{ mb: 3 }}>
                    <Typography component="h4" variant="h4" sx={{ fontWeight: "500", mb: 1 }}>
                      Campaign Title
                    </Typography>

                    <TextField
                      id="campaignTitle"
                      name="campaignTitle"
                      label="Enter title here"
                      defaultValue=""
                      variant="standard"
                      fullWidth
                      size="medium"
                      color="secondary"
                      sx={{ borderRadius: 0, borderWidth: 2, borderColor: "secondar#FFC600y" }}
                    />
                  </Box>


                  <Box sx={{ mb: 2 }}>
                    <Typography component="p" variant="h4" sx={{ fontWeight: "500", mb: 1 }}>
                      Campaign Topic
                    </Typography>

                    <TextField
                      margin="normal"
                      fullWidth
                      id="previousCampaign"
                      label="Enter campaign name or topic"
                      name="previousCampaign"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      sx={{ borderRadius: 40, boxShadow: "2px 6px 4px rgba(0, 0, 0, 0.25)" }}
                    />
                  </Box>

                  <Box sx={{ mb: 3, display: "flex", flexWrap: "wrap", gap: 1.6 }}>
                    <Chip label="Memorial Day" variant="outlined" color="primary" />
                    <Chip label="4th of July" variant="outlined" color="primary" />
                    <Chip label="Taco Tuesday" variant="outlined" color="primary" />
                    <Chip label="Christmas" color="secondary" sx={{ borderColor: "transparent" }} />
                    <Chip label="Valentines Day" variant="outlined" color="primary" />
                    <Chip label="New Years" variant="outlined" color="primary" />
                    <Chip label="2 for 1 Tuesday" variant="outlined" color="primary" />
                    <Chip label="Super Bowl" variant="outlined" color="primary" />
                    <Chip label="Grand Opening" variant="outlined" color="primary" />
                    <Chip label="Easter" variant="outlined" color="primary" />
                    <Chip label="Hanukkah" variant="outlined" color="primary" />
                    <Chip label="World Cup" variant="outlined" color="primary" />
                    <Chip label="Add Custom Campaign" variant="outlined" color="primary" icon={<AddIcon />} />
                  </Box>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Typography component="p" variant="h4" sx={{ fontWeight: "500", mb: 2, mt: 4 }}>
                      Choose Duration
                    </Typography>

                    <Grid container spacing={2}>
                      <Grid item>
                        <DesktopDatePicker
                          className="MuiDesktopDate"
                          label=""
                          inputFormat="MM/DD/YYYY"
                          size="small"
                          //value="2014-08-18T21:11:54"
                          //onChange={() => { }}
                          renderInput={(params) => <TextField {...params} />}
                          components={{
                            OpenPickerIcon: ArrowDropDownIcon
                          }}
                        />
                      </Grid>

                      <Grid item>
                        <Typography component="p" variant="h3" sx={{ fontWeight: "300", mt: 1.2 }}>
                          -
                        </Typography>
                      </Grid>

                      <Grid item>
                        <DesktopDatePicker
                          className="MuiDesktopDate"
                          label=""
                          inputFormat="MM/DD/YYYY"
                          size="small"
                          //value="2014-08-18T21:11:54"
                          //onChange={() => { }}
                          renderInput={(params) => <TextField {...params} />}
                          components={{
                            OpenPickerIcon: ArrowDropDownIcon
                          }}
                        />
                      </Grid>

                      <Grid item>
                        <FormControl variant="standard" sx={{ minWidth: 120, }}>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            className="Mui-selectduration"
                            value={10}
                            onChange={() => { }}
                            sx={{ borderBottom: 0, fontStyle: "italic", height: 58, fontWeight: "300", fontSize: 18, color: "#000000" }}
                          >
                            <MenuItem value={10}>1 Quarter</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Container>
    );
  }
}

export default (injectIntl(StepCampaign));