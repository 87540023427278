import React, { Component } from "react";
import { injectIntl } from "react-intl";
import Helmet from "react-helmet";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Link as RLink } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import CssBaseline from "@mui/material/CssBaseline";
import FormControl from "@mui/material/FormControl";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import Divider from "@mui/material/Divider";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";

class CreateCampaign extends Component {

	/*constructor(props) {
		super(props);
	}*/

	render() {
		const { intl } = this.props;

		return (
			<>
				<Helmet>
					<title>
						{intl.formatMessage({ id: "dt.create_campaign", defaultMessage: "Create Campaign" })}
					</title>
				</Helmet>

				<Container component="main" sx={{ maxWidth: "100% !important" }}>
					<CssBaseline />
					<Box
						sx={{
							marginTop: { sm: 8, xs: 4 },
							display: "flex",
							flexDirection: "column",
							alignItems: "left",
						}}
					>
						<Typography component="h1" variant="h1" sx={{ mb: 2 }}>
							{intl.formatMessage({ id: "create_campaign.title", defaultMessage: "Create Campaign", })}
						</Typography>
						<Divider sx={{ borderColor: "#C6C6C6", pt: 0, mb: 3 }} />

						<Grid container>
							<Grid item sm={10} sx={{ mx: "auto" }}>
								<Box sx={{ mb: 3 }}>
									<Typography component="h4" variant="h4" sx={{ fontWeight: "500", mb: 1 }}>
										Campaign Title
									</Typography>

									<TextField
										id="campaignTitle"
										name="campaignTitle"
										label="Enter title here"
										defaultValue=""
										variant="standard"
										fullWidth
										size="medium"
										color="secondary"
										sx={{ borderRadius: 0, borderWidth: 2, borderColor: "secondar#FFC600y" }}
									/>
								</Box>


								<Box sx={{ mb: 2 }}>
									<Typography component="p" variant="h4" sx={{ fontWeight: "500", mb: 1 }}>
										Campaign Topic
									</Typography>

									<TextField
										margin="normal"
										fullWidth
										id="previousCampaign"
										label="Enter campaign name or topic"
										name="previousCampaign"
										size="small"
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<SearchIcon />
												</InputAdornment>
											),
										}}
										sx={{ borderRadius: 40, boxShadow: "2px 6px 4px rgba(0, 0, 0, 0.25)" }}
									/>
								</Box>

								<Box sx={{ mb: 3, display: "flex", flexWrap: "wrap", gap: 1.6 }}>
									<Chip label="Memorial Day" variant="outlined" color="primary" />
									<Chip label="4th of July" variant="outlined" color="primary" />
									<Chip label="Taco Tuesday" variant="outlined" color="primary" />
									<Chip label="Christmas" color="secondary" sx={{ borderColor: "transparent" }} />
									<Chip label="Valentines Day" variant="outlined" color="primary" />
									<Chip label="New Years" variant="outlined" color="primary" />
									<Chip label="2 for 1 Tuesday" variant="outlined" color="primary" />
									<Chip label="Super Bowl" variant="outlined" color="primary" />
									<Chip label="Grand Opening" variant="outlined" color="primary" />
									<Chip label="Easter" variant="outlined" color="primary" />
									<Chip label="Hanukkah" variant="outlined" color="primary" />
									<Chip label="World Cup" variant="outlined" color="primary" />
									<Chip label="Add Custom Campaign" variant="outlined" color="primary" icon={<AddIcon />} />
								</Box>

								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<Typography component="p" variant="h4" sx={{ fontWeight: "500", mb: 2, mt: 4 }}>
										Choose Duration
									</Typography>

									<Grid container spacing={2}>
										<Grid item>
											<FormControl variant="standard" sx={{ minWidth: 120, }}>
												<Select
													labelId="demo-simple-select-standard-label"
													id="demo-simple-select-standard"
													className="Mui-selectduration"
													value={10}
													onChange={() => { }}
													sx={{ borderBottom: 0, fontStyle: "italic", height: 58, fontWeight: "300", fontSize: 18, color: "#000000" }}
												>
													<MenuItem value={10}>1 Quarter</MenuItem>
													<MenuItem value={20}>Twenty</MenuItem>
													<MenuItem value={30}>Thirty</MenuItem>
												</Select>
											</FormControl>
										</Grid>
									</Grid>
									<Grid item>
										<Box textAlign="right" >
											<RLink
												component="button"
												variant="contained"
												color="secondary"
												size="large"
												to="/home"
											>
												<Button
													type="submit"
													variant="contained"
													color="secondary"
													size="large"
													sx={{ mt: 2, ml: "auto" }}
												>
													Finish Campaign
												</Button>
											</RLink>
										</Box>
									</Grid>
								</LocalizationProvider>
							</Grid>
						</Grid>
					</Box>
				</Container>
			</>
		);
	}
}

export default (injectIntl(CreateCampaign));