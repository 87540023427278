export const LANGUAGES = {
  "en-gb": {
    id: "en-gb",
    name: "english",
    file_name: "en.js"
  }
};

//Default web language
export const DEFAULT_LANGUAGE = "en-gb";

// Sidebar Menu
export const sidebarMenuConfig = {
  "home": { name: "home", route: "home", icon: "iconHome", showBottomBoder: false },
  "manageRestaurants": { name: "manageRestaurants", route: "manage-restaurants", icon: "iconRestaurant", showBottomBoder: false },
  "uploadImages": { name: "uploadImages", route: "upload-images", icon: "iconAddImg", showBottomBoder: false },
  "imageLibrary": { name: "imageLibrary", route: "image-library", icon: "iconCamera", showBottomBoder: false },
  "textLibrary": { name: "textLibrary", route: "text-library", icon: "iconText", showBottomBoder: true },
  "createPost": { name: "createPost", route: "create-post", icon: "iconTrending", showBottomBoder: false },
  "createCampaign": { name: "createCampaign", route: "create-campaign", icon: "iconCampaign", showBottomBoder: false },
  "marketingCalender": { name: "marketingCalender", route: "marketing-calender", icon: "iconCalender", showBottomBoder: false },
  "subscriptions": { name: "subscriptions", route: "subscriptions", icon: "iconCheck", showBottomBoder: false },
  "analytics": { name: "analytics", route: "analytics", icon: "iconAnalytics", showBottomBoder: true },
  "contactDiningTek": { name: "contactDiningTek", route: "contact-dining-tek", icon: "iconPhoneLink", showBottomBoder: false },
  "settings": { name: "settings", route: "settings", icon: "iconSetting", showBottomBoder: false },
};

export const tmpConstant = {
  "datepickerFormat": "dd/MM/yyyy",
  "displayDateFormat": "DD/MM/YYYY",
  "APIRequestDateFormat": "YYYY-MM-DD",
  "APIRequestFullDateFormat": "YYYY-MM-DD hh:mm:ss",
  "displayDateTimeFormat": "DD/MM/YYYY HH:mm:ss",
  "allowedDataUploadFiles": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv",
  "allowedDataUploadFilesLabel": ".xlsx, .xls, .csv",
  "allowedEnhanceUploadFiles": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv",
  "allowedEnhanceUploadFilesLabel": ".xlsx, .xls, .csv"
};

// Application routes
export const appRoutes = [
  // Default route
  /*{
    path: "/",
    exact: true,
    component: "underConstruction",
  },*/ {
    path: "/home",
    exact: true,
    component: "home",
  }, {
    path: "/manage-restaurants",
    exact: true,
    component: "underConstruction",
  }, {
    path: "/upload-images",
    exact: true,
    component: "underConstruction",
  }, {
    path: "/image-library",
    exact: true,
    component: "underConstruction",
  }, {
    path: "/text-library",
    exact: true,
    component: "underConstruction",
  }, {
    path: "/create-post",
    exact: true,
    component: "createPost",
  }, {
    path: "/create-campaign",
    exact: true,
    component: "createCampaign",
  }, {
    path: "/marketing-calender",
    exact: true,
    component: "underConstruction",
  }, {
    path: "/subscriptions",
    exact: true,
    component: "underConstruction",
  }, {
    path: "/analytics",
    exact: true,
    component: "underConstruction",
  }, {
    path: "/contact-dining-tek",
    exact: true,
    component: "underConstruction",
  }, {
    path: "/settings",
    exact: true,
    component: "underConstruction",
  }, {
    path: "/complete-signup",
    exact: true,
    component: "marketingAutomationSetup",
  }
];