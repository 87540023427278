import React, { Component, Fragment } from "react";
import { /*get as _get, */cloneDeep as _cloneDeep } from "lodash";
import { injectIntl } from "react-intl";

import TableContainer from "@mui/material/TableContainer";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import TableHead from "@mui/material/TableHead";
import DoneIcon from "@mui/icons-material/Done";
//import Checkbox from "@mui/material/Checkbox";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Container from "@mui/material/Container";
import TabContext from "@mui/lab/TabContext";
import Avatar from "@mui/material/Avatar";
import TabPanel from "@mui/lab/TabPanel";
import Table from "@mui/material/Table";
import Stack from "@mui/material/Stack";
import Tabs from "@mui/material/Tabs";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";

function createGoldRestautants(restaurantslogo, name, address, nextpost, platform) {
  return { restaurantslogo, name, address, nextpost, platform };
}

const quickGoldRestautants = [
  createGoldRestautants(
    <Stack direction="row" spacing={1}>
      <Avatar sx={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }} alt="Resturent" src={require("../../../assets/images/monogram01.png")} />
    </Stack>,
    "Boulder Burger",
    "1310 College ave",
    "Independance Day 7/4/22",
    <Stack direction="row" spacing={1.5}>
      <Avatar variant="square" src={require("../../../assets/images/logo-gmail.png")} sx={{ width: "auto", height: 20 }} />
      <Avatar variant="square" src={require("../../../assets/images/logo-facebook.png")} sx={{ width: "auto", height: 20 }} />
    </Stack>
  ),
  createGoldRestautants(
    <Stack direction="row" spacing={1}>
      <Avatar sx={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }} alt="Resturent" src={require("../../../assets/images/monogram01.png")} />
    </Stack>,
    "Pinocchios Incredible Italian",
    "3768 State Hwy 52",
    "Super Bowl Sunday 2/12/22",
    <Stack direction="row" spacing={1.5}>
      <Avatar variant="square" src={require("../../../assets/images/logo-instagram.png")} sx={{ width: "auto", height: 20 }} />
      <Avatar variant="square" src={require("../../../assets/images/logo-facebook.png")} sx={{ width: "auto", height: 20 }} />
    </Stack>
  ),
  createGoldRestautants(
    <Stack direction="row" spacing={1}>
      <Avatar sx={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }} alt="Resturent" src={require("../../../assets/images/monogram01.png")} />
    </Stack>,
    "Pinocchios Incredible Italian",
    "210 Ken Pratt Blvd #260",
    "2 for 1 Tuesday 3/9/22",
    <Stack direction="row" spacing={1.5}>
      <Avatar variant="square" src={require("../../../assets/images/logo-twitter.png")} sx={{ width: "auto", height: 20 }} />
      <Avatar variant="square" src={require("../../../assets/images/logo-instagram.png")} sx={{ width: "auto", height: 20 }} />
      <Avatar variant="square" src={require("../../../assets/images/logo-facebook.png")} sx={{ width: "auto", height: 20 }} />
    </Stack>
  ),
  createGoldRestautants(
    <Stack direction="row" spacing={1}>
      <Avatar sx={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }} alt="Resturent" src={require("../../../assets/images/monogram01.png")} />
    </Stack>,
    "Tandoori Grill",
    "619 S Broadway",
    "Christmas 12/12/22",
    <Stack direction="row" spacing={1.5}>
      <Avatar variant="square" src={require("../../../assets/images/logo-twitter.png")} sx={{ width: "auto", height: 20 }} />
    </Stack>
  ),
];
class StepRestaurants extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activeTab: "gold",
      selectedRows: []
    };
  }

  render() {
    const { activeTab, selectedRows } = this.state;
    console.log("selectedRows: ", selectedRows);
    return (
      <>
        <TabContext value={(activeTab || "").toString()}>
          <Box sx={{ borderBottom: 1, borderColor: "#000000" }}>
            <Tabs value={(activeTab || "")} onChange={(e, value) => this.setState({ activeTab: (value || "gold") })}
              variant="scrollable" scrollButtons="auto"
              TabIndicatorProps={{
                style: { transition: "none", width: "auto" }
              }}>
              <Tab
                key="gold"
                value="gold"
                label={"Gold"}
              />
              <Tab
                key="silver"
                value="silver"
                label={"Silver"}
              />
              <Tab
                key="bronze"
                value="bronze"
                label={"Bronze"}
              />
            </Tabs>
          </Box>
          <TabPanel value={(activeTab || "").toString()} sx={{ px: 0, py: 2 }}>
            {((activeTab || "gold") === "gold") && (
              <Container component="main" sx={{ maxWidth: "100% !important", padding: "0 !important" }}>
                <Grid item xs={12}>
                  <Box component="div" sx={{ margin: "15px 0" }}>
                    <TextField id="pinocchios" label="Pinocchios" variant="outlined" size="small"
                      InputProps={{
                        startAdornment: <SearchIcon color="primary" />
                      }}
                      sx={{ borderRadius: 40, boxShadow: "2px 6px 4px rgba(0, 0, 0, 0.25)" }} />
                  </Box>
                  <Box component="div">
                    <TableContainer>
                      <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ color: "#929292 !important", borderColor: "#C6C6C6 !important", width: "100px" }}></TableCell>
                            <TableCell sx={{ color: "#929292 !important", borderColor: "#C6C6C6 !important" }}>Name</TableCell>
                            <TableCell sx={{ color: "#929292 !important", borderColor: "#C6C6C6 !important" }}>Next Post</TableCell>
                            <TableCell sx={{ color: "#929292 !important", borderColor: "#C6C6C6 !important" }}>Platform</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {quickGoldRestautants.map((row, i) => (
                            <Fragment key={`mainfrag-${i}`}>
                              <TableRow
                                key={`mainrow-${i}`}
                                sx={{ "td, th": { border: "0 !important" } }}
                              >
                                <TableCell component="th" scope="row" colSpan={4}><b>P</b></TableCell>
                              </TableRow>
                              <TableRow
                                key={`main-${i}`}
                                className={((selectedRows || []).includes(i)) ? "selectedRow" : ""}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 }, /*borderTop: "1px solid #ffc600", borderBottom: "1px solid #ffc600"*/ }}
                                onClick={(e) => {
                                  const { selectedRows } = this.state;

                                  let tmpSelectedRows = _cloneDeep(selectedRows);

                                  //Add Row Index if row is Checked
                                  if (!(tmpSelectedRows || []).includes(i)) {
                                    tmpSelectedRows.push(i);
                                  } else { // Remove Row Index if row is Unchecked
                                    let index = tmpSelectedRows.indexOf(i)
                                    if (index !== -1) {
                                      tmpSelectedRows.splice(index, 1);
                                    }
                                  }
                                  this.setState({ selectedRows: tmpSelectedRows });
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  <Box component="div" sx={{ display: "flex", alignItem: "center" }}>
                                    {((selectedRows || []).includes(i)) && (
                                      <DoneIcon color="secondary" fontSize="large" sx={{ mt: 1, mr: 3 }} />
                                    )}
                                    {row.restaurantslogo}
                                  </Box>
                                </TableCell>
                                <TableCell>
                                  <Typography component="p" variant="body1">
                                    {row.name}
                                  </Typography>
                                  <Typography component="p" sx={{ fontSize: 12, color: "#6C6C6C" }}>
                                    {row.address}
                                  </Typography>
                                </TableCell>
                                <TableCell>{row.nextpost}</TableCell>
                                <TableCell>{row.platform}</TableCell>
                              </TableRow>
                            </Fragment>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
              </Container>
            )}

            {((activeTab || "silver") === "silver") && (
              <Container component="main" sx={{ maxWidth: "100% !important", padding: "0 !important" }}>
                <Grid item xs={12}>
                  <Box component="div">
                    <TextField id="pinocchios" label="Pinocchios" variant="outlined" size="small"
                      InputProps={{
                        startAdornment: <SearchIcon color="primary" />
                      }}
                      sx={{ borderRadius: 40, boxShadow: "2px 6px 4px rgba(0, 0, 0, 0.25)" }} />
                  </Box>
                  <Box component="div">
                    <TableContainer>
                      <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ color: "#929292 !important", borderColor: "#C6C6C6 !important", width: "100px" }}></TableCell>
                            <TableCell sx={{ color: "#929292 !important", borderColor: "#C6C6C6 !important" }}>Name</TableCell>
                            <TableCell sx={{ color: "#929292 !important", borderColor: "#C6C6C6 !important" }}>Next Post</TableCell>
                            <TableCell sx={{ color: "#929292 !important", borderColor: "#C6C6C6 !important" }}>Platform</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {quickGoldRestautants.map((row, i) => (
                            <Fragment key={`mainfrag-${i}`}>
                              <TableRow
                                key={`mainrow-${i}`}
                                sx={{ "td, th": { border: "0 !important" } }}
                              >
                                <TableCell component="th" scope="row" colSpan={4}><b>P</b></TableCell>
                              </TableRow>
                              <TableRow
                                key={`main-${i}`}
                                className={((selectedRows || []).includes(i)) ? "selectedRow" : ""}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 }, /*borderTop: "1px solid #ffc600", borderBottom: "1px solid #ffc600"*/ }}
                                onClick={(e) => {
                                  const { selectedRows } = this.state;

                                  let tmpSelectedRows = _cloneDeep(selectedRows);

                                  //Add Row Index if row is Checked
                                  if (!(tmpSelectedRows || []).includes(i)) {
                                    tmpSelectedRows.push(i);
                                  } else { // Remove Row Index if row is Unchecked
                                    let index = tmpSelectedRows.indexOf(i)
                                    if (index !== -1) {
                                      tmpSelectedRows.splice(index, 1);
                                    }
                                  }
                                  this.setState({ selectedRows: tmpSelectedRows });
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  <Box component="div" sx={{ display: "flex", alignItem: "center" }}>
                                    {((selectedRows || []).includes(i)) && (
                                      <DoneIcon color="secondary" fontSize="large" sx={{ mt: 1, mr: 3 }} />
                                    )}
                                    {row.restaurantslogo}
                                  </Box>
                                </TableCell>
                                <TableCell>
                                  <Typography component="p" variant="body1">
                                    {row.name}
                                  </Typography>
                                  <Typography component="p" sx={{ fontSize: 12, color: "#6C6C6C" }}>
                                    {row.address}
                                  </Typography>
                                </TableCell>
                                <TableCell>{row.nextpost}</TableCell>
                                <TableCell>{row.platform}</TableCell>
                              </TableRow>
                            </Fragment>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
              </Container>
            )}

            {((activeTab || "bronze") === "bronze") && (
              <Container component="main" sx={{ maxWidth: "100% !important", padding: "0 !important" }}>
                <Grid item xs={12}>
                  <Box component="div">
                    <TextField id="pinocchios" label="Pinocchios" variant="outlined" size="small"
                      InputProps={{
                        startAdornment: <SearchIcon color="primary" />
                      }}
                      sx={{ borderRadius: 40, boxShadow: "2px 6px 4px rgba(0, 0, 0, 0.25)" }} />
                  </Box>
                  <Box component="div">
                    <TableContainer>
                      <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ color: "#929292 !important", borderColor: "#C6C6C6 !important", width: "100px" }}></TableCell>
                            <TableCell sx={{ color: "#929292 !important", borderColor: "#C6C6C6 !important" }}>Name</TableCell>
                            <TableCell sx={{ color: "#929292 !important", borderColor: "#C6C6C6 !important" }}>Next Post</TableCell>
                            <TableCell sx={{ color: "#929292 !important", borderColor: "#C6C6C6 !important" }}>Platform</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {quickGoldRestautants.map((row, i) => (
                            <Fragment key={`mainfrag-${i}`}>
                              <TableRow
                                key={`mainrow-${i}`}
                                sx={{ "td, th": { border: "0 !important" } }}
                              >
                                <TableCell component="th" scope="row" colSpan={4}><b>P</b></TableCell>
                              </TableRow>
                              <TableRow
                                key={`main-${i}`}
                                className={((selectedRows || []).includes(i)) ? "selectedRow" : ""}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 }, /*borderTop: "1px solid #ffc600", borderBottom: "1px solid #ffc600"*/ }}
                                onClick={(e) => {
                                  const { selectedRows } = this.state;

                                  let tmpSelectedRows = _cloneDeep(selectedRows);

                                  //Add Row Index if row is Checked
                                  if (!(tmpSelectedRows || []).includes(i)) {
                                    tmpSelectedRows.push(i);
                                  } else { // Remove Row Index if row is Unchecked
                                    let index = tmpSelectedRows.indexOf(i)
                                    if (index !== -1) {
                                      tmpSelectedRows.splice(index, 1);
                                    }
                                  }
                                  this.setState({ selectedRows: tmpSelectedRows });
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  <Box component="div" sx={{ display: "flex", alignItem: "center" }}>
                                    {((selectedRows || []).includes(i)) && (
                                      <DoneIcon color="secondary" fontSize="large" sx={{ mt: 1, mr: 3 }} />
                                    )}
                                    {row.restaurantslogo}
                                  </Box>
                                </TableCell>
                                <TableCell>
                                  <Typography component="p" variant="body1">
                                    {row.name}
                                  </Typography>
                                  <Typography component="p" sx={{ fontSize: 12, color: "#6C6C6C" }}>
                                    {row.address}
                                  </Typography>
                                </TableCell>
                                <TableCell>{row.nextpost}</TableCell>
                                <TableCell>{row.platform}</TableCell>
                              </TableRow>
                            </Fragment>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
              </Container>
            )}
          </TabPanel>
        </TabContext>
      </>
    );
  }
}

export default (injectIntl(StepRestaurants));
