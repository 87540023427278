import { get as _get } from "lodash";
import api from "../helpers/api";

export const getApplication = (uri) => {

	try {
		return api().get("/User/Configuration").then((res) => {
			return {
				flag: true,
				message: (res.message || ""),
				data: _get(res, "data.data", {})
			};
		}).catch((err) => {
			return {
				flag: false,
				title: _get(err, "response.data.title", "Alert"),
				message: _get(err, "response.data.message", "Something went wrong while fetching application configuration."),
				data: {}
			};
		});
	} catch (error) {
		return {
			flag: false,
			title: "Alert",
			message: (error.message || "Something went wrong!"),
			data: {}
		};
	}
}
