import React, { Component, Fragment } from "react";
import { map as _map, get as _get, isEmpty as _isEmpty } from "lodash";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";

// mui elements
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";

// Menu icons
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternateRounded";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenuRounded";
import CalendarTodayIcon from "@mui/icons-material/CalendarTodayRounded";
import PhotoCameraIcon from "@mui/icons-material/PhotoCameraRounded";
import TextSnippetIcon from "@mui/icons-material/TextSnippetRounded";
import TrendingUpIcon from "@mui/icons-material/TrendingUpRounded";
import FactCheckIcon from "@mui/icons-material/FactCheckRounded";
import AnalyticsIcon from "@mui/icons-material/AnalyticsRounded";
import PhonelinkIcon from "@mui/icons-material/PhonelinkRounded";
import CampaignIcon from "@mui/icons-material/CampaignRounded";
import SettingsIcon from "@mui/icons-material/SettingsRounded";
import HomeIcon from "@mui/icons-material/HomeRounded";

import { Drawer } from "../../../components/common/styled-components";
import { withRouter } from "../../../components/hoc/withRouter";

import { toggleHumburgMenu } from "../../../actions/application";

import "../../../styles/css/layout/sidebar.css";

const iconList = {
  iconHome: HomeIcon,
  iconRestaurant: RestaurantMenuIcon,
  iconAddImg: AddPhotoAlternateIcon,
  iconCamera: PhotoCameraIcon,
  iconText: TextSnippetIcon,
  iconTrending: TrendingUpIcon,
  iconCampaign: CampaignIcon,
  iconCalender: CalendarTodayIcon,
  iconCheck: FactCheckIcon,
  iconAnalytics: AnalyticsIcon,
  iconPhoneLink: PhonelinkIcon,
  iconSetting: SettingsIcon
};

const CustomListItem = ({ to, primary, iconName }) => {
  const IconComponent = iconList[iconName];

  return (
    <ListItemButton
      to={to}
      selected={to === window.location.pathname}
    >
      <ListItemIcon>
        <IconComponent />
      </ListItemIcon>
      <ListItemText primary={primary} />
    </ListItemButton>
  )
};

class Sidebar extends Component {

  _callToggleHumburgMenu = () => {
    const { toggleHumburgMenu, hurmburgMenu } = this.props;

    const changeMenuType = ((hurmburgMenu || false) === false) ? true : false;

    if (typeof toggleHumburgMenu === "function") { toggleHumburgMenu(changeMenuType); }
  }

  render() {
    const { menuConfig, hurmburgMenu, intl } = this.props;

    return (
      <Drawer className="sidebar" variant="permanent" open={hurmburgMenu}>
        <Toolbar sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", px: [1] }} >
          <IconButton onClick={() => this._callToggleHumburgMenu()} />
        </Toolbar>
        
        <List component="nav">
          {_map(menuConfig, (conf, i) => {
            if (_isEmpty(conf)) { return null; }
            const name = (conf.name || "iconHome");

            return (
              <Fragment key={name}>
                <CustomListItem
                  to={`/${(conf.route || "")}`}
                  iconName={((conf.icon || "iconCoin"))}
                  primary={intl.formatMessage({ id: `menu.${name}`, defaultMessage: name })}
                />
                {((conf.showBottomBoder || false) === true) && <Divider sx={{ my: 1 }} />}
              </Fragment>
            );
          })}
        </List>
      </Drawer>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  toggleHumburgMenu: (payload) => dispatch(toggleHumburgMenu(payload))
});

const mapStateToProps = (state, props) => ({
  menuConfig: _get(state, "application.config.menu", {}),
  hurmburgMenu: _get(state, "application.hurmburgMenu", false),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Sidebar)));
