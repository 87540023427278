import React, { Component } from "react";

import { withRouter } from "../../components/hoc/withRouter";

class SignUp extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      errors: {}
    };
  }

  render() {

    return (
      <></>
    );
  }
}

export default withRouter(SignUp);