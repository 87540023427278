import axios from "axios";
import { get as _get, isEmpty as _isEmpty } from "lodash";

import { store } from "../store/configureStore";
import { deleteOauthToken } from "../actions/oauth";

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function () {

  const state = store.getState();
  const uri = _get(state, "oauth.uri", "");
  const language = _get(state, "application.lang", "en-gb");
  const token = _get(state, "oauth.token", {});
  const { token_type = "", access_token = "" } = !_isEmpty(token) ? token : {};

  const axiosInstance = axios.create({
    baseURL: `${uri}`,
    headers: {
      "Authorization": `${token_type} ${access_token}`,
      "X-Requested-With": "XMLHttpRequest",
      "X-dt-lang": language
    }
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      const statusCode = _get(error, "response.status", "");

      if (statusCode === 401) store.dispatch(deleteOauthToken());

      return Promise.reject(error);
    }
  );

  return axiosInstance;
}
