import React from "react";

const ProgressBar = (props) => {
	const { bgcolor, label, percentage } = props;

	const containerStyles = {
    height: 20,
    width: "100%",
    backgroundColor: "#e0e0de",
    borderRadius: 50,
    margin: 0
  }

  const fillerStyles = {
    height: "100%",
    width: `${(percentage || 0)}%`,
    backgroundColor: bgcolor,
    borderRadius: "inherit",
    textAlign: "right"
  }

  const labelStyles = {
    padding: 5,
    color: "black",
    fontWeight: "bold"
  }

	return (
		<div style={containerStyles}>
			<div style={fillerStyles}>
				<span style={labelStyles}>{(label || "")}</span>
			</div>
		</div>
	);
};

export default ProgressBar;