import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";

import { withRouter } from "../../../components/hoc/withRouter";

import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

class GetStarted extends Component {

  constructor(props) {
    super(props);

    this.state = {
      errors: {}
    };
  }

  render() {

    return (
      <Container component="div" sx={{ maxWidth: "100% !important", padding: "0 !important" }}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: {
              sm: 8,
              xs: 4,
            },
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
          }}>

          <Grid container spacing={4}>
            <Grid item md={6} sm={12} xs={12} sx={{ mx: "auto" }}>
              <Card sx={{ boxShadow: "none", borderRadius: 0, py: { sm: 8, xs: 4 } }}>
                <CardContent>
                  <Box component="div" className="text-center">
                    <Typography component="h2" variant="h1" sx={{ mb: 1 }}>
                      <FormattedMessage id="getstart.title" defaultMessage="Let’s Get Started!" />
                    </Typography>

                    <Typography component="p" variant="h5" sx={{ mb: 6 }}>Lets walkthrough creating a new marketing campaign.</Typography>

                    <CheckCircleIcon color="secondary" sx={{ fontSize: { sm: "180px", xs: "90px" } }} />

                    <Grid item md={7} sm={12} xs={12} sx={{ mx: "auto" }}>
                      <Box component="div" sx={{ mt: { md: 16, xs: 3 } }}>
                        <Link component="button" variant="contained" color="secondary" size="large" to="/home">
                          <Button color="secondary" fullWidth variant="contained">Continue</Button>
                        </Link>
                      </Box>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container >
    );
  }
}

export default injectIntl(withRouter(GetStarted));