import React, { Component } from "react";
import { cloneDeep as _cloneDeep, get as _get, isEmpty as _isEmpty, isEqual as _isEqual } from "lodash";
import { injectIntl, FormattedMessage } from "react-intl";
import { withCookies, Cookies } from "react-cookie";
import { Navigate } from "react-router-dom";
import { instanceOf } from "prop-types";
import { connect } from "react-redux";
import Helmet from "react-helmet";

import { NavigateBefore } from "@mui/icons-material";
import { Link as RLink } from "react-router-dom";

import FormControlLabel from "@mui/material/FormControlLabel";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import TabContext from "@mui/lab/TabContext";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TabPanel from "@mui/lab/TabPanel";
import Tabs from "@mui/material/Tabs";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";


import { encryptData, decryptData } from "../../helpers/crypto";
import { isValidEmail } from "../../helpers/utils.js";

import { doLogin, clearOauthError, clearOauthSuccess } from "../../actions/oauth";

import facebookImage from "../../assets/images/logo-facebook.png";
import googleImage from "../../assets/images/logo-google.png";
import appleImage from "../../assets/images/logo-apple.png";
import emailImage from "../../assets/images/logo-gmail.png";

import "../../styles/css/pages/signIn.css";

class SignIn extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);

    const { cookies } = props;

    const tokenData = decryptData(cookies.get("LgnData"));

    this.defaultCredentials = {
      email: _get(tokenData, "email", ""),
      password: _get(tokenData, "key", ""),
      remember: _get(tokenData, "rm", false)
    };

    this.state = {
      activeTab: "marketAgency",
      credentials: _cloneDeep(this.defaultCredentials),
      errors: {},
      successAlert: {},
      errorAlert: {},
      disabled: true
    };
  }

  componentDidMount() {
    const { success, error, clearOauthError } = this.props;

    if (!_isEmpty(success)) {
      this.setState({
        successAlert: {
          title: (success.title || "Success"),
          message: (success.message || "Something went wrong!"),
        },
      });

      if (typeof this.props.clearOauthSuccess === "function") { this.props.clearOauthSuccess(); }
    }

    if (!_isEmpty(error)) {
      this.setState({
        errorAlert: {
          title: (error.title || "Alert"),
          message: (error.message || "Something went wrong!")
        }
      });

      if (typeof clearOauthError === "function") { clearOauthError(); }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_isEqual(prevState.credentials, this.state.credentials)) { this._handleValidation(); }

    if (!_isEqual(prevProps.error, this.props.error) && !_isEmpty(this.props.error)) {
      this.setState({
        errorAlert: {
          title: _get(this.props, "error.title", "Alert"),
          message: _get(this.props, "error.message", ""),
        },
      });

      if (typeof this.props.clearOauthError === "function") { this.props.clearOauthError(); }
    }

    if (!_isEqual(prevProps.token, this.props.token) && !_isEmpty(this.props.token)) {
      const { cookies, token } = this.props;

      const tokenCookie = encryptData({ "token": token });
      cookies.set("tknData", tokenCookie, { path: "/", domain: `.${process.env.REACT_APP_FRONTEND_DOMAIN}` });
    }
  }

  _handleChange = (e) => {
    e.preventDefault();

    const { name, value } = (e.target || {});

    this.setState((prevState) => ({
      credentials: {
        ...prevState.credentials,
        [name]: value
      },
      errorAlert: {},
      successAlert: {}
    }));
  }

  _handleValidation = () => {
    const { intl } = this.props;
    const { credentials: { email, password }, } = this.state;
    let errors = {};

    if (!isValidEmail(email)) {
      errors["email"] = intl.formatMessage({ id: "error.invalid", defaultMessage: "Invalid {field}" }, {
        field: intl.formatMessage({ id: "username", defaultMessage: "username", }),
      });
    }

    if (_isEmpty(password)) {
      errors["password"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "password", defaultMessage: "password", }),
      });
    }

    this.setState({
      errorAlert: {},
      successAlert: {},
      disabled: !_isEmpty(errors),
      errors
    });
  }

  _doLogin = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const { doLogin, cookies } = this.props;
    const { credentials: { email, password, remember }, errors } = this.state;

    if (_isEmpty(email) || _isEmpty(password) || !_isEmpty(errors)) {
      this._handleValidation();

      return;
    }

    if (typeof doLogin === "function") { doLogin({ userName: email, password }); }

    const cookieData = encryptData({ "email": (email || ""), "key": (password || ""), "rm": (remember || false) });

    if (remember === true) {
      cookies.set("LgnData", cookieData, { path: "/", domain: `.${process.env.REACT_APP_FRONTEND_DOMAIN}` });
    } else {
      cookies.remove("LgnData", { path: "/", domain: `.${process.env.REACT_APP_FRONTEND_DOMAIN}` });
    }
  }

  render() {
    const { token, intl } = this.props;
    const { activeTab/*, errors, errorAlert, successAlert, disabled*/ } = this.state;

    const search = _get(window, "location.search", "");

    if (!_isEmpty(token)) {
      const params = new URLSearchParams(search);

      const url = (params.get("continue") || "/");

      return (<Navigate to={url} />);
    }

    return (
      <>
        <Helmet>
          <title>
            {intl.formatMessage({
              id: "dt.login",
              defaultMessage: "Dining Tek: DiningTek is a Restaurant Marketing and Sales Partner. DiningTek empowers Restaurants to Grow their Business",
            })}
          </title>
        </Helmet>

        <Container component="main" maxWidth="md">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 4,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} display={{ xs: "none", lg: "flex", }}>
                <Link href="#" sx={{ color: "#000000", textDecoration: "none" }} >
                  <NavigateBefore sx={{ fontSize: 40 }} />
                </Link>
              </Grid>

              <Grid item md={10}>
                <Typography component="h1" variant="h1" sx={{ mb: 2 }}>
                  <FormattedMessage id="login.title" defaultMessage="Sign In" />
                </Typography>

                <TabContext value={(activeTab || "").toString()}>
                  <Box sx={{ borderBottom: 1, borderColor: "#000000" }}>
                    <Tabs value={(activeTab || "")} onChange={(e, value) => this.setState({ activeTab: (value || "marketAgency") })}
                      variant="scrollable" scrollButtons="auto"
                      TabIndicatorProps={{
                        style: { transition: "none", width: "auto" }
                      }}>
                      <Tab
                        key="diner"
                        value="diner"
                        label={intl.formatMessage({ id: "dt.login.tab.diner", defaultMessage: "Diner", })}
                      />
                      <Tab
                        key="restaurantOwner"
                        value="restaurantOwner"
                        label={intl.formatMessage({ id: "dt.login.tab.restaurant_owner", defaultMessage: "Restaurant Owner", })}
                      />
                      <Tab
                        key="marketAgency"
                        value="marketAgency"
                        label={intl.formatMessage({ id: "dt.login.tab.market_agency", defaultMessage: "Market Agency", })}
                      />
                    </Tabs>
                  </Box>

                  <TabPanel value={(activeTab || "").toString()}>
                    {((activeTab || "marketAgency") === "diner") && (
                      <Box component="form" noValidate>
                        <Button
                          className="btn-sign"
                          variant="outlined"
                          color="primary"
                          startIcon={<Avatar variant="square" src={googleImage} sx={{ width: "auto", height: "auto" }} />}
                          fullWidth
                          sx={{ mb: 2 }}
                        >
                          Sign in with google
                        </Button>

                        <Button
                          className="btn-sign"
                          variant="outlined"
                          color="primary"
                          startIcon={<Avatar variant="square" src={facebookImage} sx={{ width: "auto", height: "auto" }} />}
                          fullWidth
                          sx={{ mb: 2 }}
                        >
                          Sign in with facebook
                        </Button>

                        <Button
                          className="btn-sign"
                          variant="outlined"
                          color="primary"
                          startIcon={<Avatar variant="square" src={appleImage} sx={{ width: "auto", height: "auto" }} />}
                          fullWidth
                          sx={{ mb: 2 }}
                        >
                          Sign in with apple
                        </Button>

                        <Button
                          className="btn-sign"
                          variant="outlined"
                          color="primary"
                          startIcon={<Avatar variant="square" src={emailImage} sx={{ width: "auto", height: "auto" }} />}
                          fullWidth
                          sx={{ mb: 2 }}
                        >
                          Sign up with email
                        </Button>

                        <Typography component="p" variant="h3" align="center"
                          sx={{ fontSize: "20px", textTransform: "capitalize", color: "#969696" }}>
                          <FormattedMessage id="or" defaultMessage="Or" />
                        </Typography>

                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="email"
                          label="Username or Email"
                          name="email"
                          autoComplete="email"
                          autoFocus
                        />
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          name="password"
                          label="Password"
                          type="password"
                          id="password"
                          autoComplete="current-password"
                        />

                        <Box
                          component="div"
                          display="flex"
                          flexDirection={{ xs: "column", sm: "row", }}
                          justifyContent={{ sm: "space-between" }}
                          alignItems={{ sm: "center" }}
                        >
                          <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                          />

                          <Box sx={{ color: "#969696" }}>
                            <span>Forgot your password?</span>

                            <Link href="#" sx={{ color: "#0066FF", textDecoration: "none", ml: 2 }}>
                              Click Here
                            </Link>
                          </Box>
                        </Box>

                        <Box
                          textAlign="right"
                        >
                          <RLink
                            component="button"
                            variant="contained"
                            color="secondary"
                            size="large"
                            to="/complete-signup"
                          ><Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            size="large"
                            sx={{ mt: 2, ml: "auto" }}
                          >
                              Sign In
                            </Button>
                          </RLink>
                        </Box>
                      </Box>
                    )}

                    {((activeTab || "marketAgency") === "restaurantOwner") && (
                      <Box component="form" noValidate>
                        <Button
                          className="btn-sign"
                          variant="outlined"
                          color="primary"
                          startIcon={<Avatar variant="square" src={googleImage} sx={{ width: "auto", height: "auto" }} />}
                          fullWidth
                          sx={{ mb: 2 }}
                        >
                          Sign in with google
                        </Button>

                        <Button
                          className="btn-sign"
                          variant="outlined"
                          color="primary"
                          startIcon={<Avatar variant="square" src={facebookImage} sx={{ width: "auto", height: "auto" }} />}
                          fullWidth
                          sx={{ mb: 2 }}
                        >
                          Sign in with facebook
                        </Button>

                        <Button
                          className="btn-sign"
                          variant="outlined"
                          color="primary"
                          startIcon={<Avatar variant="square" src={appleImage} sx={{ width: "auto", height: "auto" }} />}
                          fullWidth
                          sx={{ mb: 2 }}
                        >
                          Sign in with apple
                        </Button>

                        <Button
                          className="btn-sign"
                          variant="outlined"
                          color="primary"
                          startIcon={<Avatar variant="square" src={emailImage} sx={{ width: "auto", height: "auto" }} />}
                          fullWidth
                          sx={{ mb: 2 }}
                        >
                          Sign up with email
                        </Button>

                        <Typography component="p" variant="h3" align="center"
                          sx={{ fontSize: "20px", textTransform: "capitalize", color: "#969696" }}>
                          <FormattedMessage id="or" defaultMessage="Or" />
                        </Typography>

                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="email"
                          label="Username or Email"
                          name="email"
                          autoComplete="email"
                          autoFocus
                        />
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          name="password"
                          label="Password"
                          type="password"
                          id="password"
                          autoComplete="current-password"
                        />

                        <Box
                          component="div"
                          display="flex"
                          flexDirection={{ xs: "column", sm: "row", }}
                          justifyContent={{ sm: "space-between" }}
                          alignItems={{ sm: "center" }}
                        >
                          <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                          />

                          <Box sx={{ color: "#969696" }}>
                            <span>Forgot your password?</span>

                            <Link href="#" sx={{ color: "#0066FF", textDecoration: "none", ml: 2 }}>
                              Click Here
                            </Link>
                          </Box>
                        </Box>

                        <Box
                          textAlign="right"
                        >
                          <RLink
                            component="button"
                            variant="contained"
                            color="secondary"
                            size="large"
                            to="/complete-signup"
                          ><Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            size="large"
                            sx={{ mt: 2, ml: "auto" }}
                          >
                              Sign In
                            </Button>
                          </RLink>
                        </Box>
                      </Box>
                    )}

                    {((activeTab || "marketAgency") === "marketAgency") && (
                      <Box>
                        <Button
                          className="btn-sign"
                          variant="outlined"
                          color="primary"
                          startIcon={<Avatar variant="square" src={googleImage} sx={{ width: "auto", height: "auto" }} />}
                          fullWidth
                          sx={{ mb: 2 }}
                        >
                          Sign in with google
                        </Button>

                        <Button
                          className="btn-sign"
                          variant="outlined"
                          color="primary"
                          startIcon={<Avatar variant="square" src={facebookImage} sx={{ width: "auto", height: "auto" }} />}
                          fullWidth
                          sx={{ mb: 2 }}
                        >
                          Sign in with facebook
                        </Button>

                        <Button
                          className="btn-sign"
                          variant="outlined"
                          color="primary"
                          startIcon={<Avatar variant="square" src={appleImage} sx={{ width: "auto", height: "auto" }} />}
                          fullWidth
                          sx={{ mb: 2 }}
                        >
                          Sign in with apple
                        </Button>

                        <Button
                          className="btn-sign"
                          variant="outlined"
                          color="primary"
                          startIcon={<Avatar variant="square" src={emailImage} sx={{ width: "auto", height: "auto" }} />}
                          fullWidth
                          sx={{ mb: 2 }}
                        >
                          Sign up with email
                        </Button>

                        <Typography component="p" variant="h3" align="center"
                          sx={{ fontSize: "20px", textTransform: "capitalize", color: "#969696" }}>
                          <FormattedMessage id="or" defaultMessage="Or" />
                        </Typography>

                        <TextField
                          margin="normal"
                          //required
                          fullWidth
                          id="email"
                          label="Username or Email"
                          name="email"
                          autoComplete="email"
                          autoFocus
                        />
                        <TextField
                          margin="normal"
                          //required
                          fullWidth
                          name="password"
                          label="Password"
                          type="password"
                          id="password"
                          autoComplete="current-password"
                        />

                        <Box
                          component="div"
                          display="flex"
                          flexDirection={{ xs: "column", sm: "row", }}
                          justifyContent={{ sm: "space-between" }}
                          alignItems={{ sm: "center" }}
                        >
                          <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                          />

                          <Box sx={{ color: "#969696" }}>
                            <span>Forgot your password?</span>

                            <Link href="#" sx={{ color: "#0066FF", textDecoration: "none", ml: 2 }}>
                              Click Here
                            </Link>
                          </Box>
                        </Box>

                        <Box
                          textAlign="right"
                        >
                          <RLink
                            component="button"
                            variant="contained"
                            color="secondary"
                            size="large"
                            to="/complete-signup"
                          ><Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            size="large"
                            sx={{ mt: 2, ml: "auto" }}
                          >
                              Sign In
                            </Button>
                          </RLink>
                        </Box>
                      </Box>
                    )}
                  </TabPanel>
                </TabContext>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  error: _get(state, "oauth.error", {}),
  token: _get(state, "oauth.token"),
  success: _get(state, "oauth.success", {}),
});

const mapDispatchToProps = (dispatch) => ({
  doLogin: (data) => dispatch(doLogin(data)),
  clearOauthError: () => dispatch(clearOauthError()),
  clearOauthSuccess: () => dispatch(clearOauthSuccess()),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withCookies(SignIn)));
