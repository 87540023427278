import { takeLatest, call, fork, select, put } from "redux-saga/effects";
import { getApplication } from "../services/application";
import { get as _get } from "lodash";

import {
  UPDATE_APPLICATION_LOADING, FETCH_APPLICATION_REQUEST, FETCH_APPLICATION_SUCCESS
} from "../actions/application";

function* workerFetchApplication(action) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const uri = yield select((state) => _get(state, "oauth.uri", ""));

    const response = yield call(getApplication, uri);

    if ((response.flag || false) === true) {
      yield put({
        type: FETCH_APPLICATION_SUCCESS,
        payload: (response.data || {})
      });
    }

  } catch (error) {

  } finally {

    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* watcherFetchApplication() {
  yield takeLatest(FETCH_APPLICATION_REQUEST, workerFetchApplication);
}

/* eslint import/no-anonymous-default-export: [2, {"allowArray": true}] */
export default [
  fork(watcherFetchApplication)
];
