import { showAlertMessage as showAlertTmp } from "./AlertMessage";
import { ErrorMessage as ErrorMessageTmp } from "./ErrorMessage";
import { NoDataMessage as NoDataMessageTmp } from "./EmptyLabel";
import { EmptyLabel as EmptyLabelTmp } from "./EmptyLabel";
import { ReactModal as ReactModalTmp } from "./ReactModal";
import { CustomModal as ModalTmp } from "./CustomModal";
import { Textarea as TextareaTmp } from "./Textarea";
// import { Input as InputTmp } from "./Input";
import DateRangePickerTmp from "./DateRangePicker";
import ProgressBarTmp from "./ProgressBar";
import ConfirmBoxTmp from "./ConfirmBox";
import SelectTmp from "./Select";

export const DateRangePicker = DateRangePickerTmp;
export const NoDataMessage = NoDataMessageTmp;
export const showAlertMessage = showAlertTmp;
export const ErrorMessage = ErrorMessageTmp;
export const ProgressBar = ProgressBarTmp;
export const ReactModal = ReactModalTmp;
export const EmptyLabel = EmptyLabelTmp;
export const ConfirmBox = ConfirmBoxTmp;
export const Textarea = TextareaTmp;
export const Select = SelectTmp;
// export const Input = InputTmp;
export const Modal = ModalTmp;
