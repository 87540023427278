import React, { Component } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";

import { withRouter } from "../../components/hoc/withRouter";
import { appRoutes } from "./../../helpers/constants";

import MarketingAutomationSetup from "../MarketingAutomationSetup";
import UnderConstruction from "../GenericPages/UnderConstruction";
import CreateCampaign from "../CreateCampaign";
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import CreatePost from "../CreatePost";
import Home from "../Home";

import { fetchApplication } from "../../actions/application";

const components = {
  marketingAutomationSetup: MarketingAutomationSetup,
  underConstruction: UnderConstruction,
  createCampaign: CreateCampaign,
  createPost: CreatePost,
  home: Home,
}

class Application extends Component {

  componentDidMount() {
    // const { fetchApplication } = this.props;

    // if (typeof fetchApplication === "function") { fetchApplication(); }
  }

  render() {
    return (
      <React.Fragment>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />

          <Navbar />
          <Sidebar />

          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.white
                  : theme.palette.grey[900],
              flexGrow: 1,
              // height: "100vh",
              overflow: "auto",
            }}
          >
            <Box component="div" sx={{ pt: 6, mb: 2, maxWidth: "100% !important" }}>
              <Routes>
                {
                  appRoutes.map(({ component, ...rest }, key) => {

                    const RouteComponent = (components[component] || "");

                    if (typeof RouteComponent !== "undefined" && RouteComponent !== "") {
                      return <Route key={key} {...rest} element={<RouteComponent />} />
                    } else {
                      return null;
                    }
                  })
                }
                <Route path="*" element={<Navigate from="*" to="/" replace />} />
              </Routes>
            </Box>
          </Box>
        </Box>
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchApplication: () => dispatch(fetchApplication())
});

export default withRouter(connect(null, mapDispatchToProps)(injectIntl(Application)));
