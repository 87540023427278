import { get as _get } from "lodash";
import api from "../helpers/api";

export const doLogin = (uri, variables) => {
	return api().post("/Authenticate/token", variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while login!"),
			data: {}
		};
	});
}

export const doForgotPassword = (variables) => {
	return api().patch("/user/password/reset/initiate", variables).then((res) => {
		return {
			flag: _get(res, "data.status", false),
			message: _get(res, "data.message", ""),
			data: _get(res, "data", {}),
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", false),
			message: _get(err, "response.data.message", "Something went wrong while generating forgot password link."),
			data: {},
		};
	});
};

export const checkResetPassword = (variables) => {
	return api().patch("/user/password/reset/criteria", variables).then((res) => {
		return {
			flag: _get(res, "data.status", false),
			message: _get(res, "data.message", ""),
			data: _get(res, "data", {}),
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while check reset password criteria."),
			data: {},
		};
	});
};

export const doResetPassword = (variables) => {
	return api().patch("/user/password/reset", variables).then((res) => {
		return {
			flag: _get(res, "data.status", false),
			message: _get(res, "data.message", ""),
			data: _get(res, "data", {}),
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", false),
			message: _get(err, "response.data.message", "Something went wrong while reseting password."),
			data: {},
		};
	});
};