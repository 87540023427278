import { takeLatest, call, fork, select, put } from "redux-saga/effects";
import { get as _get } from "lodash";

import { doLogin, doForgotPassword, checkResetPassword, doResetPassword } from "../services/oauth";

import {
  OAUTH_LOGIN_REQUEST, OAUTH_LOGIN_SUCCESS, OAUTH_LOGIN_ERROR,
  FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_ERROR,
  CHECK_RESET_PASSWORD_CRITERIA, RESET_PASSWORD_VALID, RESET_PASSWORD_INVALID,
  RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_ERROR
} from "../actions/oauth";

import { UPDATE_APPLICATION_LOADING } from "../actions/application";

function* workerDoLogin(action) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const uri = yield select((state) => _get(state, "oauth.uri", ""));

    const response = yield call(doLogin, uri, action.data);

    //This is to check if error or not
    if ((response.flag || false) === true) {
      yield put({
        type: OAUTH_LOGIN_SUCCESS,
        token: response.data || {},
      });
    } else {
      yield put({
        type: OAUTH_LOGIN_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while login."),
        },
      });
    }
  } catch (error) {
    yield put({
      type: OAUTH_LOGIN_ERROR,
      error: {
        title: _get(error, "response.title", "Alert"),
        message: _get(error, "response.message", "Something went wrong while login."),
      },
    });
  } finally {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerDoForgot(action) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(doForgotPassword, action.data);

    if ((response.flag || false) === true) {
      yield put({
        type: FORGOT_PASSWORD_SUCCESS,
        data: (response.flag || {}),
        message: {
          title: _get(response, "title", "Success"),
          message: _get(response, "message", "Something went wrong while generating forgot password link."),
        },
      });
    } else {
      yield put({
        type: FORGOT_PASSWORD_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while generating forgot password link."),
        },
      });
    }
  } catch (error) {
    yield put({
      type: FORGOT_PASSWORD_ERROR,
      error: {
        title: _get(error, "response.title", "Alert"),
        message: _get(error, "response.message", "Something went wrong while generating forgot password link."),
      },
    });
  } finally {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerCheckReset(action) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(checkResetPassword, action.data);

    if ((response.flag || false) === true) {
      yield put({
        type: RESET_PASSWORD_VALID,
        resetToken: _get(response, "data.data.id", "Alert"),
        data: (response.flag || false),
      });
    } else {
      yield put({
        type: RESET_PASSWORD_INVALID,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while validating reset password link."),
        },
      });
    }
  } catch (error) {
    yield put({
      type: RESET_PASSWORD_INVALID,
      error: {
        title: _get(error, "response.title", "Alert"),
        message: _get(error, "response.message", "Something went wrong while validating reset password link."),
      },
    });
  } finally {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* workerDoReset(action) {
  try {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: true });

    const response = yield call(doResetPassword, action.data);

    if ((response.flag || false) === true) {
      yield put({
        type: RESET_PASSWORD_SUCCESS,
        data: (response.flag || false),
        message: {
          title: _get(response, "title", "Success"),
          message: (response.message || "Password reset successfully.")
        },
      });
    } else {
      yield put({
        type: RESET_PASSWORD_ERROR,
        error: {
          title: _get(response, "title", "Alert"),
          message: _get(response, "message", "Something went wrong while reseting password."),
        },
      });
    }
  } catch (error) {
    yield put({
      type: RESET_PASSWORD_ERROR,
      error: {
        title: _get(error, "response.title", "Alert"),
        message: _get(error, "response.message", "Something went wrong while reseting password."),
      },
    });
  } finally {
    yield put({ type: UPDATE_APPLICATION_LOADING, loading: false });
  }
}

function* watcherDoLogin() {
  yield takeLatest(OAUTH_LOGIN_REQUEST, workerDoLogin);
}

function* watcherDoForgot() {
  yield takeLatest(FORGOT_PASSWORD_REQUEST, workerDoForgot);
}

function* watcherCheckReset() {
  yield takeLatest(CHECK_RESET_PASSWORD_CRITERIA, workerCheckReset);
}

function* watcherDoReset() {
  yield takeLatest(RESET_PASSWORD_REQUEST, workerDoReset);
}

/* eslint import/no-anonymous-default-export: [2, {"allowArray": true}] */
export default [
  fork(watcherDoLogin),
  fork(watcherDoForgot),
  fork(watcherCheckReset),
  fork(watcherDoReset)
];
