import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";

import { withRouter } from "../../../components/hoc/withRouter";

import StayPrimaryPortraitIcon from "@mui/icons-material/StayPrimaryPortrait";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

class Welcome extends Component {

  constructor(props) {
    super(props);

    this.state = {
      errors: {}
    };
  }

  render() {
    const { handleNext } = this.props;

    return (
      <Container component="div" sx={{ maxWidth: "100% !important", padding: "0 !important" }}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: {
              sm: 8,
              xs: 4,
            },
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
          }}>

          <Typography component="h1" variant="h1" sx={{ mb: 10 }}>
            <FormattedMessage id="welcome.title" defaultMessage="Welcome to Dining Tek" />
          </Typography>

          <Grid container spacing={10}>
            <Grid item md={10} sm={12} xs={12} sx={{ mx: "auto" }}>
              <Box components="div" className="d-flex align-items-center">
                <StayPrimaryPortraitIcon color="secondary" sx={{ fontSize: 80, marginRight: 3 }} />

                <Box components="div">
                  <Typography component="h4" variant="h4" sx={{ mb: 0.5 }}>
                    Create Social Media Posts
                  </Typography>

                  <Typography component="p" variant="h4" sx={{ color: "#858585" }}>
                    Description of what you can do with posting
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item md={10} sm={12} xs={12} sx={{ mx: "auto" }}>
              <Box components="div" className="d-flex align-items-center">
                <AutorenewIcon color="secondary" sx={{ fontSize: 80, marginRight: 3 }} />

                <Box components="div">
                  <Typography component="h4" variant="h4" sx={{ mb: 0.5 }}>
                    Automate and Schedule Posting
                  </Typography>

                  <Typography component="p" variant="h4" sx={{ color: "#858585" }}>
                    Description of how you can schedule posts
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item md={10} sm={12} xs={12} sx={{ mx: "auto" }}>
              <Box components="div" className="d-flex align-items-center">
                <AnalyticsIcon color="secondary" sx={{ fontSize: 80, marginRight: 3 }} />

                <Box components="div">
                  <Typography component="h4" variant="h4" sx={{ mb: 0.5 }}>
                    Advanced Analytics
                  </Typography>

                  <Typography component="p" variant="h4" sx={{ color: "#858585" }}>
                    Description of how we provide analytics and what they do
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item md={10} sm={12} xs={12} sx={{ mx: "auto" }}>
              <Box components="div" className="text-end" sx={{ mt: 10 }} onClick={() => { if (typeof handleNext === "function") { handleNext(); } }}>
                <Button color="secondary" variant="contained">Continue</Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    );
  }
}

export default injectIntl(withRouter(Welcome));