import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";

import { withRouter } from "../../../components/hoc/withRouter";

import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

class SubscriptionPlan extends Component {

  constructor(props) {
    super(props);

    this.state = {
      errors: {}
    };
  }

  render() {
    const { handleNext } = this.props;

    return (
      <Container component="div" sx={{ maxWidth: "100% !important", padding: "0 !important" }}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: {
              sm: 8,
              xs: 4,
            },
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
          }}>

          <Typography component="h1" variant="h1" sx={{ mb: 1 }}>
            <FormattedMessage id="subscription.title" defaultMessage="Choose a Subscription Plan" />
          </Typography>
          <Typography component="p" variant="h5" sx={{ mb: 5 }}>
            Choose which plan fits your restaurant’s needs the best
          </Typography>

          <Grid container spacing={3}>
            <Grid item md={10} sm={12} xs={12} sx={{ mx: "auto" }}>
              <Box component="div" className="d-flex align-items-center" sx={{ border: "1px solid #DADADA", p: 2, flexDirection: { md: "row", xs: "column" } }}>
                <Typography component="h4" variant="h4" className="fw-bold" sx={{ mr: { md: 3.2 } }}>
                  Free
                </Typography>

                <Box component="div">
                  <Typography className="fst-italic" component="h4" variant="h4" sx={{ mb: { md: 0.5, xs: 2 }, mt: { md: 0, xs: 2 }, textAlign: { md: "left", xs: "center" } }}>
                    Free Plan
                  </Typography>

                  <Box component="ul" className="list-styled list-inline-styled">
                    <Box component="li">
                      <Typography component="p" variant="body" sx={{ color: "#858585" }}>
                        Listing on Dining Tek Only
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item md={10} sm={12} xs={12} sx={{ mx: "auto" }}>
              <Box component="div" className="d-flex align-items-center" sx={{ border: "1px solid #DADADA", p: 2, flexDirection: { md: "row", xs: "column" } }}>
                <Avatar alt="Bronze" src={require("../../../assets/images/icon-bronze.png")} variant="square" sx={{ height: 84, width: 41, mr: { md: 5 } }} />

                <Box component="div">
                  <Typography className="fst-italic" component="h4" variant="h4" sx={{ mb: { md: 0.5, xs: 2 }, mt: { md: 0, xs: 2 }, textAlign: { md: "left", xs: "center" } }}>
                    Bronze $1
                  </Typography>

                  <Box component="ul" className="list-styled list-inline-styled">
                    <Box component="li">
                      <Typography component="p" variant="body" sx={{ color: "#858585" }}>
                        Listing on Dining Tek Only
                      </Typography>
                    </Box>

                    <Box component="li">
                      <Typography component="p" variant="body" sx={{ color: "#858585" }}>
                        Receive Online Orders
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item md={10} sm={12} xs={12} sx={{ mx: "auto" }}>
              <Box component="div" className="d-flex align-items-center" sx={{ border: "1px solid #DADADA", p: 2, flexDirection: { md: "row", xs: "column" } }}>
                <Avatar alt="Bronze" src={require("../../../assets/images/icon-silver.png")} variant="square" sx={{ height: 84, width: 41, mr: { md: 5 } }} />

                <Box component="div">
                  <Typography className="fst-italic" component="h4" variant="h4" sx={{ mb: { md: 0.5, xs: 2 }, mt: { md: 0, xs: 2 }, textAlign: { md: "left", xs: "center" } }}>
                    Silver $2
                  </Typography>

                  <Box component="ul" className="list-styled list-inline-styled">
                    <Box component="li">
                      <Typography component="p" variant="body" sx={{ color: "#858585" }}>
                        Listing on Dining Tek Only
                      </Typography>
                    </Box>

                    <Box component="li">
                      <Typography component="p" variant="body" sx={{ color: "#858585" }}>
                        Receive Online Orders
                      </Typography>
                    </Box>

                    <Box component="li">
                      <Typography component="p" variant="body" sx={{ color: "#858585" }}>
                        Recieve Online Reservations
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item md={10} sm={12} xs={12} sx={{ mx: "auto" }}>
              <Box component="div" className="d-flex align-items-center" sx={{ border: "1px solid #DADADA", p: 2, flexDirection: { md: "row", xs: "column" } }}>
                <Avatar alt="Bronze" src={require("../../../assets/images/icon-gold.png")} variant="square" sx={{ height: 84, width: 41, mr: { md: 5 } }} />

                <Box component="div">
                  <Typography className="fst-italic" component="h4" variant="h4" sx={{ mb: { md: 0.5, xs: 2 }, mt: { md: 0, xs: 2 }, textAlign: { md: "left", xs: "center" } }}>
                    Gold $3
                  </Typography>

                  <Box component="ul" className="list-styled list-inline-styled">
                    <Box component="li">
                      <Typography component="p" variant="body" sx={{ color: "#858585" }}>
                        Listing on Dining Tek Only
                      </Typography>
                    </Box>

                    <Box component="li">
                      <Typography component="p" variant="body" sx={{ color: "#858585" }}>
                        Receive Online Orders
                      </Typography>
                    </Box>

                    <Box component="li">
                      <Typography component="p" variant="body" sx={{ color: "#858585" }}>
                        Recieve Online Reservations
                      </Typography>
                    </Box>

                    <Box component="li">
                      <Typography component="p" variant="body" sx={{ color: "#858585" }}>
                        Additional Advertisement
                      </Typography>
                    </Box>

                    <Box component="li">
                      <Typography component="p" variant="body" sx={{ color: "#858585" }}>
                        Marketing Automation
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item md={11} sm={12} xs={12} sx={{ mx: "auto" }}>
              <Box component="div" className="text-end" sx={{ mt: 6 }} onClick={() => { if (typeof handleNext === "function") { handleNext(); } }}>
                <Button color="secondary" variant="contained">Continue</Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    );
  }
}

export default injectIntl(withRouter(SubscriptionPlan));