import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";

import { withRouter } from "../../../components/hoc/withRouter";

import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import DoneIcon from "@mui/icons-material/Done";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";

class Checkout extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activeTab: "personaldetails",
      errors: {}
    };
  }

  render() {
    const { activeTab } = this.state;
    const { handleNext } = this.props;

    return (
      <Container component="div" sx={{ maxWidth: "100% !important", padding: "0 !important" }}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: {
              sm: 8,
              xs: 4,
            },
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
          }}>

          <Grid container spacing={4}>
            <Grid item md={7} sm={12} xs={12}>
              <Paper elevation={0} square sx={{ height: "100%" }}>
                <Card sx={{ padding: { sm: 2, xs: 0 }, height: "100%" }}>
                  <CardContent>
                    <Typography component="h2" variant="h1" sx={{ mb: 3 }}>
                      <FormattedMessage id="checkout.title" defaultMessage="Checkout" />
                    </Typography>

                    <TabContext value={(activeTab || "").toString()}>
                      <Box sx={{ borderBottom: 1, borderColor: "#000000" }}>
                        <Tabs value={(activeTab || "")} onChange={(e, value) => this.setState({ activeTab: (value || "personaldetails") })}
                          variant="scrollable" scrollButtons="auto"
                          TabIndicatorProps={{
                            style: { transition: "none", width: "auto" }
                          }}>
                          <Tab
                            key="personaldetails"
                            value="personaldetails"
                            label={"Personal Details"}
                          />
                          <Tab
                            key="paymentdetails"
                            value="paymentdetails"
                            label={"Payment Details"}
                          />
                          <Tab
                            key="confirminformation"
                            value="confirminformation"
                            label={"Confirm Information"}
                          />
                        </Tabs>
                      </Box>

                      <TabPanel value={(activeTab || "").toString()} sx={{ p: 0, pt: 3, minHeight: { lg: "720px", xs: 0 } }}>
                        {((activeTab || "personaldetails") === "personaldetails") && (
                          <Container component="div" sx={{ maxWidth: "100% !important", padding: "0 !important" }}>
                            <Grid container>
                              <Grid item xs={12}>
                                <Typography component="h6" variant="h4" className="fw-semibold d-inline-block" sx={{ mb: 3 }}>Personal Details</Typography>
                                <Typography component="h6" variant="h4" className="fst-italic d-inline-block" sx={{ mb: 3, ml: 2 }}>
                                  Signed In <DoneIcon className="align-middle" />
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                              <Grid item md={6} xs={12}>
                                <Typography component="label" variant="h5" className="d-block" sx={{ mb: 1 }}>First Name</Typography>
                                <TextField id="firstname" label="First Name" fullWidth variant="outlined" />
                              </Grid>
                              <Grid item md={6} xs={12}>
                                <Typography component="label" variant="h5" className="d-block" sx={{ mb: 1 }}>Last Name</Typography>
                                <TextField id="lastname" label="Last Name" fullWidth variant="outlined" />
                              </Grid>
                              <Grid item md={6} xs={12}>
                                <Typography component="label" variant="h5" className="d-block" sx={{ mb: 1 }}>Phone</Typography>
                                <TextField id="phone" label="Phone" fullWidth variant="outlined" />
                              </Grid>
                              <Grid item md={6} xs={12}>
                                <Typography component="label" variant="h5" className="d-block" sx={{ mb: 1 }}>Email</Typography>
                                <TextField id="email" label="Email" fullWidth variant="outlined" />
                              </Grid>
                              <Grid item md={12} xs={12}>
                                <Typography component="label" variant="h5" className="d-block" sx={{ mb: 1 }}>Address</Typography>
                                <TextField id="address" label="Address" fullWidth variant="outlined" />
                              </Grid>
                              <Grid item md={6} xs={12}>
                                <Typography component="label" variant="h5" className="d-block" sx={{ mb: 1 }}>City</Typography>
                                <TextField id="city" label="City" fullWidth variant="outlined" />
                              </Grid>
                              <Grid item md={6} xs={12}>
                                <Typography component="label" variant="h5" className="d-block" sx={{ mb: 1 }}>Zip Code</Typography>
                                <TextField id="zipcode" label="Zip Code" fullWidth variant="outlined" />
                              </Grid>
                              <Grid item md={6} xs={12}>
                                <Typography component="label" variant="h5" className="d-block" sx={{ mb: 1 }}>State</Typography>
                                <TextField id="state" label="State" fullWidth variant="outlined" />
                              </Grid>
                              <Grid item md={6} xs={12}>
                                <Typography component="label" variant="h5" className="d-block" sx={{ mb: 1 }}>Country</Typography>
                                <TextField id="country" label="Country" fullWidth variant="outlined" />
                              </Grid>
                              <Grid item md={12} xs={12}>
                                <Box component="div" className="text-end" sx={{ mt: 3 }} onClick={() => this.setState({ activeTab: "paymentdetails" })}>
                                  <Button type="submit" variant="contained" color="secondary">Next Step</Button>
                                </Box>
                              </Grid>
                            </Grid>
                          </Container>
                        )}
                        {((activeTab || "paymentdetails") === "paymentdetails") && (
                          <Container component="div" sx={{ maxWidth: "100% !important", padding: "0 !important" }}>
                            <Grid container>
                              <Grid item xs={12}>
                                <Typography component="h6" variant="h4" className="fw-semibold d-inline-block" sx={{ mb: 3 }}>Choose a Payment Method </Typography>
                              </Grid>
                            </Grid>

                            <Grid container spacing={3} className="justify-content-center">
                              <Grid item xl={5} lg={6} md={6} xs={12}>
                                <Box components="div" className="d-flex" sx={{ height: "100%", padding: 3, borderRadius: 1, border: "1px solid #DADADA" }}>
                                  <Avatar variant="square" src={require("../../../assets/images/icon-merchant-account.png")} alt="Bank Account" />

                                  <Box components="div" sx={{ ml: 2 }}>
                                    <Typography component="h4" variant="h5" sx={{ mb: 0.5 }}>
                                      Bank Account
                                    </Typography>

                                    <Typography component="p" variant="h5" sx={{ color: "#858585" }}>
                                      Connect your Bank Account Information with DIning Tek
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item xl={5} lg={6} md={6} xs={12}>
                                <Box components="div" className="d-flex" sx={{ height: "100%", padding: 3, borderRadius: 1, border: "1px solid #FFC600" }}>
                                  <Avatar variant="square" src={require("../../../assets/images/icon-credit-card.png")} alt="Credit Card" />

                                  <Box components="div" sx={{ ml: 2 }}>
                                    <Typography component="h4" variant="h5" sx={{ mb: 0.5 }}>
                                      Credit Card
                                    </Typography>

                                    <Typography component="p" variant="h5" sx={{ color: "#858585" }}>
                                      Pay Using Credit Card
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item md={12} xs={12}>
                                <Box component="div" className="text-end" sx={{ mt: 3 }} onClick={() => this.setState({ activeTab: "confirminformation" })}>
                                  <Button type="submit" variant="contained" color="secondary">Next Step</Button>
                                </Box>
                              </Grid>
                            </Grid>
                          </Container>
                        )}
                        {((activeTab || "confirminformation") === "confirminformation") && (
                          <Container component="div" sx={{ maxWidth: "100% !important", padding: "0 !important" }}>
                            <Grid container>
                              <Grid item xs={12}>
                                <Typography component="h6" variant="h4" className="fw-semibold d-inline-block" sx={{ mb: 3 }}>Choose a Payment Method </Typography>
                              </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                              <Grid item md={6} xs={12}>
                                <Typography component="label" variant="h5" className="d-block" sx={{ mb: 1 }}>Card Number</Typography>
                                <TextField id="cardnumber" label="1111-2222-3333-4444" fullWidth variant="outlined" />
                              </Grid>
                              <Grid item md={6} xs={12}>
                                <Typography component="label" variant="h5" className="d-block" sx={{ mb: 1 }}>CVV</Typography>
                                <TextField id="cvv" label="123" fullWidth variant="outlined" />
                              </Grid>
                              <Grid item md={3} xs={12}>
                                <Typography component="label" variant="h5" className="d-block" sx={{ mb: 1 }}>Exp Date</Typography>
                                <TextField id="expdate" label="00/0000" fullWidth variant="outlined" />
                              </Grid>
                              <Grid item md={12} xs={12}>
                                <Box component="div" className="text-end" sx={{ mt: 3 }} onClick={() => { if (typeof handleNext === "function") { handleNext(); } }}>
                                  <Button type="submit" variant="contained" color="secondary">Confirm Payment</Button>
                                </Box>
                              </Grid>
                            </Grid>
                          </Container>
                        )}
                      </TabPanel>
                    </TabContext>
                  </CardContent>
                </Card>
              </Paper>
            </Grid>

            <Grid item md={5} sm={12} xs={12}>
              <Paper elevation={0} square sx={{ height: "100%" }}>
                <Card sx={{ padding: { sm: 2, xs: 0 }, height: "100%" }}>
                  <CardContent>
                    <Typography component="h2" variant="h1" sx={{ mb: 3 }}>
                      <FormattedMessage id="cart.title" defaultMessage="Cart" />
                    </Typography>

                    <Box component="div" className="d-flex align-items-center">
                      <Avatar variant="square" src={require("../../../assets/images/logo-checkout.png")} alt="Dining Tek" sx={{ height: "50px", width: "107px", mr: 2 }} />

                      <Box component="div">
                        <Typography component="p" variant="h6" sx={{ mb: 0.5 }}>Checkout From</Typography>
                        <Typography component="h6" variant="h3" className="fw-semibold" sx={{ mb: 1 }}>Dining Tek</Typography>
                      </Box>
                    </Box>

                    <Box component="div" sx={{ mt: 2.5 }}>
                      <Divider />
                      <Box component="div" sx={{ py: 2 }}>
                        <Box component="div" className="d-lg-flex align-items-center justify-content-between">
                          <Typography component="div" variant="h4" className="fw-semibold">Enter Coupon Code</Typography>
                          <Button type="submit" variant="contained" color="secondary" size="small"
                            sx={{ minWidth: "auto", fontSize: "14px", px: 2, mt: { sm: 0, xs: 2 } }}
                          >Apply Coupon</Button>
                        </Box>
                      </Box>
                      <Divider />
                      <Box component="div" sx={{ py: 2 }}>
                        <Typography component="div" variant="h4" className="fw-semibold">Order Summary</Typography>
                        <Box component="div" className="d-flex align-items-center justify-content-between" sx={{ pt: 1, pl: 2 }}>
                          <Typography component="div" variant="h5">Silver Tier</Typography>
                          <Typography component="div" variant="h5">$2</Typography>
                        </Box>
                      </Box>
                      <Divider />
                      <Box component="div" sx={{ py: 2 }}>
                        <Box component="div" className="d-flex align-items-center justify-content-between">
                          <Typography component="div" variant="h4" className="fw-semibold">Total</Typography>
                          <Typography component="div" variant="h5">$2</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container >
    );
  }
}

export default injectIntl(withRouter(Checkout));