import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import Helmet from "react-helmet";

class UnderConstruction extends Component {

  render() {
    const { intl } = this.props;

    return (
      <>
        <Helmet>
          <title> {intl.formatMessage({ id: "dt.generic_page.comming_soon", defaultMessage: "Comming soon: Dining Tek" })} </title>
        </Helmet>

        <div className="text-center mt-10">
          <div className="text-uppercase">
            <h2 className="title--bold">
              <FormattedMessage id="generic_page.coming_soon.title" defaultMessage="coming soon" />
            </h2>
          </div>
          <div>
            <h3><FormattedMessage id="generic_page.coming_soon.sub_title" defaultMessage="This page is under construction." /></h3>
          </div>
        </div>
      </>
    );
  }
}

export default injectIntl(UnderConstruction);