import React, { Component } from "react";
import { cloneDeep as _cloneDeep, isEqual as _isEqual, isEmpty as _isEmpty, get as _get } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { Navigate, Link } from "react-router-dom";
import { connect } from "react-redux";
import Helmet from "react-helmet";

import { withRouter } from "../../components/hoc/withRouter";
import config from "../../helpers/config";

import { checkResetPasswordCriteria, doResetPassword, clearCheckResetResponse, clearResetResponse } from "../../actions/oauth";

class ResetPasswordUser extends Component {
  constructor(props) {
    super(props);

    this.defaultCredentials = {
      id: "",
      resetKey: "",
      newPassword: "",
      confirmPassword: ""
    };

    this.state = {
      credentials: _cloneDeep(this.defaultCredentials),
      errors: {},
      errorAlert: {},
      resetKey: "",
      showForm: true,
      disabled: true
    };

    this.legalUrl = config.getFrontendURL();
  }

  componentDidMount(prevProps, prevState) {
    const { checkResetPasswordCriteria } = this.props;

    let pathArray = window.location.pathname.split("/");

    if (pathArray[3]) {
      if (typeof checkResetPasswordCriteria === "function") { checkResetPasswordCriteria({ reset_key: pathArray[3], subdomain: this.legalUrl }); }
    }
  }

  componentDidUpdate(prevProps, prevState) {

    if (!_isEqual(prevState.credentials, this.state.credentials)) { this._handleValidation(); }

    if (!_isEqual(prevProps.error, this.props.error) && !_isEmpty(this.props.error)) {
      this.setState({
        errorAlert: { title: _get(this.props, "error.title", "Alert"), message: _get(this.props, "error.message", ""), }
      });
    }

    if (!_isEqual(prevProps.resetResponse, this.props.resetResponse)) {
      this.setState({ showForm: this.props.resetResponse });

      if (typeof this.props.clearCheckResetResponse === "function") { this.props.clearCheckResetResponse(); }
    }

    if (!_isEqual(prevProps.resetToken, this.props.resetToken) && this.props.resetToken > 0) {
      this.setState({
        credentials: { id: this.props.resetToken, }
      });

      if (typeof this.props.clearResetResponse === "function") { this.props.clearResetResponse(); }
    }

    if (!_isEqual(prevProps.resetPassword, this.props.resetPassword) && this.props.resetPassword === true) {
      if (typeof this.props.clearResetResponse === "function") { this.props.clearResetResponse(); }
    }
  }

  _handleChange = (e) => {
    e.preventDefault();

    const { name, value } = (e.target || {});

    this.setState((prevState) => ({
      credentials: {
        ...prevState.credentials,
        [name]: value,
      },
      errorAlert: {},
    }));
  }

  _doResetPassword = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const { doResetPassword } = this.props;
    const { credentials: { id, newPassword, confirmPassword }, errors } = this.state;

    if (
      _isEmpty(newPassword) ||
      !_isEmpty(errors) ||
      (id === 0 || !_isEmpty(errors))
    ) {
      this._handleValidation();
      return;
    }

    if (typeof doResetPassword === "function") { doResetPassword({ id: id, new_password: newPassword, confirm_password: confirmPassword, subdomain: this.legalUrl }); }

  }

  _handleValidation = () => {
    const { intl } = this.props;
    const { credentials: { newPassword, confirmPassword } } = this.state;

    let errors = {};

    if (_isEmpty(newPassword)) {
      errors["newPassword"] = intl.formatMessage({ id: "error.required", defaultMessage: "{field} is required" }, {
        field: intl.formatMessage({ id: "new_password", defaultMessage: "New Password" })
      });
    }

    if (!_isEqual(newPassword, confirmPassword)) {
      errors["confirmPassword"] = intl.formatMessage({ id: "error.password_not_match", defaultMessage: "{field} is no matched" }, {
        field: intl.formatMessage({ id: "confirm_password", defaultMessage: "Confirm Password", })
      });
    }

    this.setState({
      errorAlert: {},
      disabled: !_isEmpty(errors),
      errors,
    });
  }

  render() {
    const { resetPassword = false, intl } = this.props;
    const { errors, errorAlert, disabled, showForm } = this.state;

    if (resetPassword === true) { return (<Navigate to="/sign-in" />); }
    if (showForm === false) { return (<Navigate to="/sign-in" />); }

    return (
      <>
        <Helmet>
          <title> {intl.formatMessage({ id: "dt.reset_password", defaultMessage: "Reset Password: Dining Tek" })} </title>
        </Helmet>

        <section>
          <div className="loginSection">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="loginContent">
                    <div className="loginCenter">
                      <div className="loginBox">
                        <h2 className="text-capitalize">{intl.formatMessage({ id: "reset_password.title", defaultMessage: "Reset password" })}</h2>
                        {!_isEmpty(errorAlert) && (
                          <div className="error-msg">
                            <span>{(errorAlert.title || "")}</span>
                            <span>{(errorAlert.message || "")}</span>
                          </div>
                        )}
                        <p className="text-capitalize-first">{intl.formatMessage({ id: "reset_password.sub_title", defaultMessage: "Enter new password below." })}</p>
                        <form className="loginForm" onSubmit={this._doResetPassword} >
                          <div className="form-group">
                            <div className="form-group">
                              <span className="loginIcons">
                               
                              </span>
                              <input
                                type="password"
                                name="newPassword"
                                className="form-control text-capitalize-first"
                                placeholder={intl.formatMessage({ id: "new_password", defaultMessage: "New Password" })}
                                onChange={this._handleChange}
                              />
                              {(errors.newPassword || "") && (
                                <p className="error text-capitalize-first"> {errors.newPassword} </p>
                              )}
                            </div>
                            <div className="form-group">
                              <span className="loginIcons">
                                
                              </span>
                              <input
                                type="password"
                                name="confirmPassword"
                                className="form-control text-capitalize-first"
                                placeholder={intl.formatMessage({ id: "confirm_password", defaultMessage: "Confirm Password", })}
                                onChange={this._handleChange}
                              />
                              {(errors.confirmPassword || "") && (
                                <p className="error text-capitalize-first"> {errors.confirmPassword} </p>
                              )}
                            </div>
                          </div>
                          <div className="btn_section">
                            <div className="login_fogt_btn">
                              <button
                                type="submit"
                                disabled={disabled}
                              >
                                <FormattedMessage id="btn.update" defaultMessage="Reset" />
                              </button>
                            </div>
                            <div className="forgot_link">
                              <Link to="/sign-in" className="text-capitalize">
                                <FormattedMessage id="forgot_password.login_text" defaultMessage="Back to login" />
                              </Link>
                            </div>
                          </div>
                        </form>
                        <p className="awareNote m-t-20">
                          <FormattedMessage id="login.warning_text" defaultMessage="Please be aware that the unauthorised access to systems or data may result in discipliary action." />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  error: _get(state, "oauth.error", {}),
  resetResponse: _get(state, "oauth.resetResponse", true),
  resetPassword: _get(state, "oauth.resetPassword", false),
  resetToken: _get(state, "oauth.resetToken", false)
});

const mapDispatchToProps = (dispatch) => ({
  checkResetPasswordCriteria: (data) => dispatch(checkResetPasswordCriteria(data)),
  doResetPassword: (data) => dispatch(doResetPassword(data)),
  clearCheckResetResponse: () => dispatch(clearCheckResetResponse()),
  clearResetResponse: () => dispatch(clearResetResponse())
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ResetPasswordUser)));