import { createTheme } from "@mui/material/styles";
// import createBreakpoints from "@mui/system/createTheme/createBreakpoints";
import { createBreakpoints } from "@mui/system";

import WorkSansWoff300 from "../../../assets/fonts/WorkSans-Light.woff";
import WorkSansWoff400 from "../../../assets/fonts/WorkSans-Regular.woff";
import WorkSansWoff500 from "../../../assets/fonts/WorkSans-Medium.woff";
import WorkSansWoff600 from "../../../assets/fonts/WorkSans-SemiBold.woff";
import WorkSansWoff700 from "../../../assets/fonts/WorkSans-Bold.woff";

const breakpoints = createBreakpoints({});

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    allVariants: {
      color: "#000000",
      fontFamily: `"Work Sans", "Helvetica", "Arial", sans-serif`,
      fontWeight: "400",
      fontSize: 16,
    },
    h1: {
      fontSize: 48,
      fontWeight: "600",
      letterSpacing: "0.5px",
      [breakpoints.down("sm")]: {
        letterSpacing: 0,
        fontSize: 36
      }
    },
    h2: {
      fontSize: 36,
      fontWeight: "500",
      letterSpacing: "0.5px",
      [breakpoints.down("sm")]: {
        letterSpacing: 0,
        fontSize: 32
      }
    },
    h3: {
      fontSize: 30,
      fontWeight: "500",
      letterSpacing: "0.5px",
      [breakpoints.down("sm")]: {
        letterSpacing: 0,
        fontSize: 20
      }
    },
    h4: {
      fontSize: 24,
      letterSpacing: "0.5px",
      [breakpoints.down("sm")]: {
        letterSpacing: 0,
        fontSize: 18
      }
    },
    h5: {
      fontSize: 20,
      letterSpacing: "0.5px",
      [breakpoints.down("sm")]: {
        letterSpacing: 0,
        fontSize: 18,
      }
    },
    body1: {
      [breakpoints.down("sm")]: {
        fontSize: 12
      }
    }

    // subtitle1: {
    //   fontSize: 12,
    // },
    // // body1: {
    // //   fontWeight: 500,
    // // },
    // // button: {
    // //   fontStyle: "italic",
    // // },
    // button: {
    //   fontSize: 16,
    //   textTransform: "uppercase",
    //   fontWeight: 700,
    // }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: "Work Sans";
        font-style: normal;
        font-weight: 300;
        src: url(${WorkSansWoff300}) format("woff");
      }

      @font-face {
        font-family: "Work Sans";
        font-style: normal;
        font-weight: 400;
        src: url(${WorkSansWoff400}) format("woff");
      }

      @font-face {
        font-family: "Work Sans";
        font-style: normal;
        font-weight: 500;
        src: url(${WorkSansWoff500}) format("woff");
      }

      @font-face {
        font-family: "Work Sans";
        font-style: normal;
        font-weight: 600;
        src: url(${WorkSansWoff600}) format("woff");
      }

      @font-face {
        font-family: "Work Sans";
        font-style: normal;
        font-weight: 700;
        src: url(${WorkSansWoff700}) format("woff");
      }
      `,
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: 40,
          [breakpoints.up("sm")]: {
            minHeight: 50,
          },
        }
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          [breakpoints.up("md")]: {
            paddingLeft: 40,
            paddingRight: 40,
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: "18px",
          fontWeight: "600",
          minWidth: "200px",
          boxShadow: "none",
          borderRadius: "100px",
          letterSpacing: "0.1px",
          textTransform: "capitalize",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#000",
          "&$checked": {
            color: "#000",
          },
        },
        label: {
          color: "#969696",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#969696",
          letterSpacing: 0.5,
          [breakpoints.down("xs")]: {
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.MuiOutlinedInput-root": {
            color: "#969696",
            borderRadius: "15px !important",
            backgroundColor: "#fff",
            letterSpacing: 0.5,
            [breakpoints.down("xs")]: {
              borderRadius: "50px !important",
            }
          },
        }
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          "&.MuiTabs-root-small ": {
            minHeight: 40,
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: "#000000",
          padding: 0,
          minWidth: "auto",
          minHeight: 38,
          marginRight: 30,
          textTransform: "capitalize",
          "&.Mui-selected": {
            color: "#000000",
            borderRadius: 25,
            backgroundColor: "#FFC600",
            fontWeight: "500",
            padding: "0 20px",
          },
          "&.Mui-small": {
            padding: "5px 0",
            minHeight: "28px",
            "&.Mui-selected": {
              padding: "0 20px",
            }
          },
        }
      }
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          [breakpoints.down("sm")]: {
            padding: "24px 0",
          }
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "0px 3px 5px 3px rgba(70, 70, 70, 0.1)",
          borderRadius: 8,
          padding: 20,
          [breakpoints.down("sm")]: {
            padding: 15,
          },
          "& .MuiCardContent-root": {
            paddingBottom: "0 !important",
          }
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          ".MuiTableHead-root": {
            ".MuiTableCell-root": {
              fontSize: 14,
              color: "#2E2E2E",
              borderColor: "#F2F2F2",
              padding: "14px 12px",
              backgroundColor: "#F7F7F7",
              whiteSpace: "nowrap",
            }
          },
          ".MuiTableBody-root": {
            ".MuiTableCell-root": {
              fontSize: 14,
              color: "#000000",
              padding: "8px 12px",
              borderBottom: "1px solid #F2F2F2",
              whiteSpace: "nowrap",
            }
          }
        }
      }
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          overflowX: "auto",
          ".MuiStep-root": {
            padding: 0,
            ".MuiButtonBase-root": {
              margin: 0,
              padding: "10px 0",
              width: "auto",

              ".MuiStepLabel-root": {
                ".MuiStepLabel-iconContainer": {
                  ".MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root": {
                    display: "none"
                  }
                },

                ".MuiStepLabel-label": {
                  display: "flex",
                  alignItem: "center",
                  fontSize: 24,
                  fontWeight: "300",
                  letterSpacing: 0.25,
                  whiteSpace: "nowrap",
                  [breakpoints.down("sm")]: {
                    fontSize: 20,
                  },
                  "&.Mui-active": {
                    fontSize: 36,
                    fontWeight: "600",
                    [breakpoints.down("sm")]: {
                      fontSize: 24,
                    }
                  },
                  ".MuiStepLabel-iconContainer": {
                    paddingLeft: 20,
                    paddingRight: 20,
                    [breakpoints.down("sm")]: {
                      paddingLeft: 7,
                      paddingRight: 7,
                    },
                    ".MuiSvgIcon-root": {
                      fontSize: 34,
                      [breakpoints.down("sm")]: {
                        fontSize: 24,
                      },
                    }
                  }
                },
              }
            }
          },

          ".MuiStepConnector-root": {
            display: "none"
          },
        }
      }
    },
    MuiAvatarGroup: {
      styleOverrides: {
        root: {
          justifyContent: "flex-start",
          flexDirection: "row",
          ".MuiAvatar-root": {
            width: 33,
            height: 33,
            fontSize: 16,
            marginLeft: "-16px",
            "&.MuiAvatar-colorDefault": {
              backgroundColor: "#FFC600",
              color: "#3D2F00",
              fontWeight: "400",
              order: 3,
            },
            "&:nth-child(2)": {
              marginLeft: 0,
            },
            "&:last-child": {
              marginLeft: "-16px",
            }
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          // background: "#FFFFFF",
          // border: "1px solid #8E8E8E",
          padding: "0 5px",
          height: "40",
          ".MuiChip-label": {
            fontWeight: "600",
          },
          "&.Mui-chipsquare": {
            backgroundColor: "#ffffff",
            borderColor: "#ffffff",
            borderRadius: 0,
            height: 40,
            paddingLeft: 10,
            paddingRight: 10,
            ".MuiAvatar-root": {
              marginLeft: 0,
              marginRight: 0,
              backgroundColor: "transparent",
            },
            "&.active": {
              border: "1px solid #000000",
            }
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "&.MuiDesktopDate": {
            ".MuiInputBase-root": {
              border: 0,
              maxWidth: "180px",
              ".MuiInputBase-input": {
                color: "#000000",
                fontSize: 18,
                fontStyle: "italic",
              },
              ".MuiOutlinedInput-notchedOutline": {
                border: 0,
              }
            }
          },
          ".Mui-selectduration": {
            "&:before": {
              content: "none",
            },
            "&:after": {
              content: "none",
            },
            ".MuiSelect-select": {
              color: "#000000",
              fontWeight: "400",
            },
            ".MuiSvgIcon-root": {
              color: "#000000",
            }
          }
        }
      }
    },
    MuiModal: {
      styleOverrides: {
        root: {
          ".Modal-body": {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 1000,
            width: "100%",
            backgroundColor: "#ffffff",
            padding: "48px 48px 24px",
          }
        }
      }
    },
  },
  palette: {
    primary: {
      light: "#F2F2F2",
      main: "#000000",
      dark: "#1C1B1F",
      contrastText: "#fff",
    },
    secondary: {
      light: "#FFF7CC",
      main: "#FFC600",
      dark: "#FFE073",
      contrastText: "#3D2F00",
    },
  },
});

export default theme;