export const UPDATE_APPLICATION_LOADING = "UPDATE_APPLICATION_LOADING";

export const updateLoadingState = (loading) => {
  return ({
    type: UPDATE_APPLICATION_LOADING,
    loading
  });
}

export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";

export const updateLanguage = (language) => {
  return ({
    type: UPDATE_LANGUAGE,
    language
  });
}

export const FETCH_APPLICATION_REQUEST = "FETCH_APPLICATION_REQUEST";
export const FETCH_APPLICATION_SUCCESS = "FETCH_APPLICATION_SUCCESS";

export const fetchApplication = () => {
  return ({
    type: FETCH_APPLICATION_REQUEST,
  });
}

export const SET_ACTIVE_MENU = "SET_ACTIVE_MENU";

export const setActiveMenuRequest = (payload) => {
  return ({
    type: SET_ACTIVE_MENU,
    payload
  });
}

export const TOGGLE_HUMBURG_MENU = "TOGGLE_HUMBURG_MENU";

export const toggleHumburgMenu = (payload) => {
  return ({
    type: TOGGLE_HUMBURG_MENU,
    payload
  });
}