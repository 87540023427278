import React, { Component } from "react";
import { injectIntl } from "react-intl";

import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";

class StepFinalEdits extends Component {

  /*constructor(props) {
    super(props);
  }*/

  render() {

    return (
      <Container component="main" sx={{ maxWidth: "100% !important", padding: "0 !important" }}>
        <CssBaseline />
        <Grid item xs={12}>
          <Divider sx={{ borderColor: "#C6C6C6", pt: 0, mb: 3 }} />
          <Box>
            <Grid container>
              <Grid item sm={7} sx={{ mx: "auto" }}>
                <Box component="div">
                  <Grid container justifyContent="space-between" spacing={2}>
                    <Grid item md={12} xs={12}>
                      <Typography component="h3" variant="h3" sx={{ fontWeight: "500" }}>
                        Preview
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>

            <Grid fullWidth>
              <Grid item sm={12}>
                <Box component="div" sx={{ backgroundColor: "#757575", padding: 5, margin: "30px 0" }}>
                  <Grid container justifyContent="center" spacing={3}>
                    <Grid item md={4} xs={12}>
                      <Box component="div" sx={{ textAlign: "center" }}>
                        <img
                          src={require("../../../assets/images/preview-01.png")}
                          alt=""
                          className="img-fluid"
                          loading="lazy"
                        />
                      </Box>
                    </Grid>

                    <Grid item md={4} xs={12}>
                      <Box component="div" sx={{ textAlign: "center" }}>
                        <img
                          src={require("../../../assets/images/preview-02.png")}
                          alt=""
                          className="img-fluid"
                          loading="lazy"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item sm={6} xs={12} sx={{ mx: "auto" }}>
                <Box component="div" sx={{ mb: 2 }}>
                  <Grid container>
                    <Grid item sm={12} xs={12} sx={{ mx: "auto" }}>
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item sm={6} xs={6}>
                          <Typography component="p" variant="h3" sx={{ fontWeight: "500" }}>
                            Edit Text
                          </Typography>
                        </Grid>

                        <Grid item sm={6} xs={6}>
                          <Box sx={{ textAlign: "right" }}>
                            <Link to="/#" color="secondary" sx={{ textDecoration: "none" }}>
                              Open Text Library
                            </Link>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>

                <Box component="div">
                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sx={{ margin: "0 auto" }}>
                      <Box component="div" sx={{ mb: 1.5, paddingLeft: { sm: 4, xs: 0 } }}>
                        <TextField
                          id="outlined-multiline-static"
                          label="Enter the Edit text"
                          multiline
                          rows={4}
                          defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis "
                          sx={{ maxWidth: "100%", width: "100%" }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item sm={6} sx={{ mx: "auto" }}>
                <Box component="div" sx={{ mb: 2 }}>
                  <Grid container>
                    <Grid item xs={12} sx={{ mx: "auto" }}>
                      <Grid container justifyContent="space-between">
                        <Grid item md={6} xs={6}>
                          <Typography component="p" variant="h3" sx={{ fontWeight: "500" }}>
                            Change Image
                          </Typography>
                        </Grid>

                        <Grid item md={6} xs={6}>
                          <Box sx={{ textAlign: "right" }}>
                            <Link to="/#" color="secondary" sx={{ textDecoration: "none" }}>
                              Open Image Library
                            </Link>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>

                <Box component="div">
                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sx={{ margin: "0 auto" }}>
                      <Box component="div" sx={{ mb: 1.5, paddingLeft: { sm: 4, xs: 0 } }}>
                        <img
                          src={require("../../../assets/images/post-06.png")}
                          alt=""
                          className="img-fluid"
                          loading="lazy"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item sm={6} sx={{ mx: "auto" }}>
                <Box component="div" sx={{ mb: 2 }}>
                  <Grid container>
                    <Grid item md={12} xs={12}>
                      <Typography component="p" variant="h3" sx={{ fontWeight: "500", mb: 2 }}>
                        Add Tags
                      </Typography>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Box sx={{ mb: 3, display: "flex", flexWrap: "wrap", paddingLeft: { sm: 4, xs: 0 }, gap: 2 }}>
                        <Chip label="Special" variant="filled" sx={{ borderColor: "transparent" }} color="secondary" icon={<CloseIcon sx={{ ml: "0 !important", mr: "0 !important" }} />} />
                        <Chip label="Offer" color="secondary" sx={{ borderColor: "transparent" }} icon={<CloseIcon sx={{ ml: "0 !important", mr: "0 !important" }} />} />
                        <Chip label="TandooriGrill" variant="filled" sx={{ borderColor: "transparent" }} color="secondary" />
                        <Chip label="DiningTek" variant="filled" sx={{ borderColor: "transparent" }} color="secondary" />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item sm={6} sx={{ mx: "auto" }}>
                <Box component="div" sx={{ mb: 2 }}>
                  <Grid container>
                    <Grid item sm={12} xs={12} sx={{ mx: "auto" }}>
                      <Typography component="p" variant="h3" sx={{ fontWeight: "500", mb: 2 }}>
                        Confirm Information
                      </Typography>
                    </Grid>

                    <Grid item sm={12} sx={{ mx: "auto" }}>
                      <Box component="div" display="flex" alignItems="center" sx={{ paddingLeft: { sm: 4, xs: 0 } }}>
                        <Stack direction="row" spacing={1.5} sx={{ pr: 4 }}>
                          <Avatar variant="square" src={require("../../../assets/images/logo-instagram.png")} sx={{ width: "auto", height: 24 }} />
                          <Avatar variant="square" src={require("../../../assets/images/logo-twitter.png")} sx={{ width: "auto", height: 24 }} />
                        </Stack>
                        <Typography component="p" variant="h5" sx={{}}>
                          Every Tuesday at 5pm <br />
                          starting  5/16/2022
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box >
        </Grid >
      </Container >
    );
  }
}

export default (injectIntl(StepFinalEdits));