import { all } from "redux-saga/effects";

import application from "./application";
import oauth from "./oauth";

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function* () {
  yield all([
    ...application,
    ...oauth,
  ]);
}