import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { get as _get } from "lodash";

import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";

import { AppBar } from "../../../components/common/styled-components";
import { withRouter } from "../../../components/hoc/withRouter";

import { toggleHumburgMenu } from "../../../actions/application";

import avatarImage from "../../../assets/images/avatar.png";
import logoImage from "../../../assets/images/logo.svg";

class Navbar extends Component {

  _callToggleHumburgMenu = () => {
    const { toggleHumburgMenu, hurmburgMenu } = this.props;

    const changeMenuType = ((hurmburgMenu || false) === false) ? true : false;

    if (typeof toggleHumburgMenu === "function") { toggleHumburgMenu(changeMenuType); }
  }

  render() {
    const { hurmburgMenu } = this.props;

    return (
      <AppBar position="absolute" open={hurmburgMenu} sx={{ width: "100%", padding: "10px 0" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => this._callToggleHumburgMenu()}
            // sx={{ marginRight: "36px", ...(hurmburgMenu && { display: "none" }) }}
            sx={{ marginRight: "12px" }}
          >
            <MenuIcon />
          </IconButton>

          <Box
            component="img"
            sx={{
              marginRight: "auto",
              maxWidth: {
                sm: "177px",
                xs: "120px",
              }
            }}
            alt="Dining Tek"
            src={logoImage}
          />

          <IconButton color="inherit" sx={{ padding: 0 }}>
            <Avatar alt="Anny Sharp" src={avatarImage} />
          </IconButton>
        </Toolbar>
      </AppBar>
    )
  };
}

const mapDispatchToProps = (dispatch) => ({
  toggleHumburgMenu: (payload) => dispatch(toggleHumburgMenu(payload))
});

const mapStateToProps = (state, props) => ({
  hurmburgMenu: _get(state, "application.hurmburgMenu", false)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Navbar)));
