import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import Helmet from "react-helmet";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TableContainer from "@mui/material/TableContainer";
import HighchartsReact from "highcharts-react-official";
import CssBaseline from "@mui/material/CssBaseline";
import AvatarGroup from "@mui/material/AvatarGroup";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Carousel from "react-material-ui-carousel";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import Container from "@mui/material/Container";
import TableRow from "@mui/material/TableRow";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Highcharts from "highcharts";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import { ProgressBar } from "../../components/common/controls";
import { withRouter } from "../../components/hoc/withRouter";


function createData(restaurants, title, date, time, platform, arrowRightIcon) {
  return { restaurants, title, date, time, platform, arrowRightIcon };
}

function createQuickAnalytics(post, views, orderplaced) {
  return { post, views, orderplaced };
}

const rows = [
  createData(
    <Box component="div" className="d-flex align-items-center">
      <AvatarGroup total={6}>
        <Avatar alt="Resturent" src={require("../../assets/images/monogram01.png")} sx={{ width: 33, height: 33 }} />
        <Avatar alt="Resturent" src={require("../../assets/images/monogram02.png")} sx={{ width: 33, height: 33 }} />
        <Avatar alt="Resturent" src={require("../../assets/images/monogram01.png")} sx={{ width: 33, height: 33 }} />
      </AvatarGroup>
      <Typography component="h6" variant="h6" className="fw-medium" marginLeft={1}>Christmas</Typography>
    </Box>,
    <Typography component="h6" variant="h6" sx={{ color: "#5D5D5D" }}>12/24 5pm</Typography>,
    "Fridays at 5pm",
    <Box>
      1 Quarter
      <Typography component="span" sx={{ color: "#5D5D5D", marginLeft: 1 }}>Ends on 12/25</Typography>
    </Box>,
    <Box className="d-flex align-items-center" sx={{ gap: 1 }}>
      <Chip label="Design" size="small" color="secondary" sx={{ backgroundColor: "#FFF7CC", color: "#DFAD00" }} />
      <Chip label="Product" size="small" color="secondary" sx={{ backgroundColor: "#FFE073", color: "#6A5900" }} />
      <Chip label="Development" size="small" color="secondary" sx={{ backgroundColor: "#FFC600", color: "#3D2F00" }} />
      <Chip label="+5" size="small" color="primary" sx={{ backgroundColor: "#F2F2F2", color: "#2E2E2E" }} />
    </Box>,
    <ChevronRightIcon className="text-end" size="small" sx={{ color: "#CECECE" }} />
  ),
  createData(
    <Box component="div" className="d-flex align-items-center">
      <AvatarGroup total={6}>
        <Avatar alt="Resturent" src={require("../../assets/images/monogram01.png")} sx={{ width: 33, height: 33 }} />
        <Avatar alt="Resturent" src={require("../../assets/images/monogram02.png")} sx={{ width: 33, height: 33 }} />
        <Avatar alt="Resturent" src={require("../../assets/images/monogram01.png")} sx={{ width: 33, height: 33 }} />
      </AvatarGroup>
      <Typography component="h6" variant="h6" className="fw-medium" marginLeft={1}>Super Bowl</Typography>
    </Box>,
    <Typography component="h6" variant="h6" sx={{ color: "#5D5D5D" }}>2/12 3pm</Typography>,
    "No",
    <Box>
      2 Quarter
      <Typography component="span" sx={{ color: "#5D5D5D", marginLeft: 1 }}>Ends on 12/25</Typography>
    </Box>,
    <Box className="d-flex align-items-center" sx={{ gap: 1 }}>
      <Chip label="Design" size="small" color="secondary" sx={{ backgroundColor: "#FFF7CC", color: "#DFAD00" }} />
      <Chip label="Product" size="small" color="secondary" sx={{ backgroundColor: "#FFE073", color: "#6A5900" }} />
      <Chip label="Development" size="small" color="secondary" sx={{ backgroundColor: "#FFC600", color: "#3D2F00" }} />
      <Chip label="+5" size="small" color="primary" sx={{ backgroundColor: "#F2F2F2", color: "#2E2E2E" }} />
    </Box>,
    <ChevronRightIcon className="text-end" size="small" sx={{ color: "#CECECE" }} />
  ),
  createData(
    <Box component="div" className="d-flex align-items-center">
      <AvatarGroup total={6}>
        <Avatar alt="Resturent" src={require("../../assets/images/monogram01.png")} sx={{ width: 33, height: 33 }} />
        <Avatar alt="Resturent" src={require("../../assets/images/monogram02.png")} sx={{ width: 33, height: 33 }} />
        <Avatar alt="Resturent" src={require("../../assets/images/monogram01.png")} sx={{ width: 33, height: 33 }} />
      </AvatarGroup>
      <Typography component="h6" variant="h6" className="fw-medium" marginLeft={1}>Independance Day</Typography>
    </Box>,
    <Typography component="h6" variant="h6" sx={{ color: "#5D5D5D" }}>7/4 12pm</Typography>,
    "Tuesdays & Thursdays at 12pm",
    <Box>
      3 Months
      <Typography component="span" sx={{ color: "#5D5D5D", marginLeft: 1 }}>Ends on 7/4</Typography>
    </Box>,
    <Box className="d-flex align-items-center" sx={{ gap: 1 }}>
      <Chip label="Design" size="small" color="secondary" sx={{ backgroundColor: "#FFF7CC", color: "#DFAD00" }} />
      <Chip label="Product" size="small" color="secondary" sx={{ backgroundColor: "#FFE073", color: "#6A5900" }} />
      <Chip label="Development" size="small" color="secondary" sx={{ backgroundColor: "#FFC600", color: "#3D2F00" }} />
      <Chip label="+5" size="small" color="primary" sx={{ backgroundColor: "#F2F2F2", color: "#2E2E2E" }} />
    </Box>,
    <ChevronRightIcon className="text-end" size="small" sx={{ color: "#CECECE" }} />
  ),
];

const quickAnalyticsRow = [
  createQuickAnalytics("Christmas", "157", "52"),
  createQuickAnalytics("Super Bowl", "375", "35"),
  createQuickAnalytics("4th of July", "45", "75"),
];

class Home extends Component {

  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      activeTab: "upcomingPost",
    };

    this.weeklyViewsOptions = { chart: { type: "column" }, title: { text: "Weekly Views" }, yAxis: { min: 0, title: { text: "Views" } }, legend: { enabled: false }, };

    this.state = {
      weeklyViews: { ...this.weeklyViewsOptions }
    };
  }

  componentDidMount() {

    const chartData = {
      ...this.weeklyViewsOptions,
      title: null,
      series: [{
        name: "Population",
        data: [
          ["S", 70],
          ["M", 31],
          ["T", 7],
          ["W", 15],
          ["T", 50],
          ["F", 21],
          ["S", 65],
        ],
        dataLabels: {
          enabled: false,
          color: "#F3F3F3",
          format: "{point.y:.1f}",
          y: 10, // 10 pixels down from the top
        }
      }]
    };

    this.setState({ weeklyViews: chartData });
  }

  render() {
    const { intl } = this.props;
    const { weeklyViews } = this.state;

    return (
      <>
        <Helmet>
          <title>
            {intl.formatMessage({ id: "dt.home", defaultMessage: "Home", })}
          </title>
        </Helmet>

        <Container component="main" sx={{ maxWidth: "100% !important" }}>
          <CssBaseline />
          <Box
            sx={{
              marginTop: {
                sm: 8,
                xs: 4,
              },
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
            }}
          >
            <Typography component="h1" variant="h1" sx={{ mb: 2 }}>
              <FormattedMessage id="home.title" defaultMessage="Home" />
            </Typography>

            <Grid container spacing={4}>
              <Grid item md={12} sm={12} xs={12}>
                <Box component="div">
                  <Typography component="h3" variant="h3" sx={{ mb: 2.5 }}>
                    Marketing Automation
                  </Typography>
                </Box>

                <Card sx={{ p: 0 }}>
                  <CardContent sx={{ p: 0 }}>
                    <TableContainer>
                      <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                          <TableRow>
                            <TableCell>Upcoming Post</TableCell>
                            <TableCell>Next Post</TableCell>
                            <TableCell>Recurring</TableCell>
                            <TableCell>Campaign Duration</TableCell>
                            <TableCell>Tags</TableCell>
                            <TableCell>&nbsp;</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row, i) => (
                            <TableRow
                              key={i}
                              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                              <TableCell component="th" scope="row">{row.restaurants}</TableCell>
                              <TableCell>{row.title}</TableCell>
                              <TableCell>{row.date}</TableCell>
                              <TableCell>{row.time}</TableCell>
                              <TableCell>{row.platform}</TableCell>
                              <TableCell>{row.arrowRightIcon}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <Box component="div" className="text-end" sx={{ margin: "15px" }}>
                      <Typography component="a" sx={{ backgroundColor: "#FFF7CC", color: "#DFAD00", padding: "8px 10px", display: "inline-block", borderRadius: "4px" }}>
                        See More
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item md={12} sm={12} xs={12}>
                <Grid container spacing={3}>
                  <Grid item md={4} sm={12} xs={12}>
                    <Box component="div" sx={{ mb: 2.5 }}>
                      <Typography component="h3" variant="h3">
                        Upcoming Events
                      </Typography>
                    </Box>
                    <Card sx={{ p: 0 }}>
                      <CardContent sx={{ p: 0 }}>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Events</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>&nbsp;</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {rows.map((row, i) => (
                                <TableRow
                                  key={i}
                                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                >
                                  <TableCell component="th" scope="row">{row.restaurants}</TableCell>
                                  <TableCell>{row.title}</TableCell>
                                  <TableCell>{row.arrowRightIcon}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>

                        <Box component="div">
                          <Link href="#" variant="body1" fontWeight="500" sx={{ padding: "12px", display: "flex", alignItems: "center", textDecoration: "none" }}>
                            <AddCircleOutlineIcon fontSize="small" sx={{ marginRight: 1 }} /> Add New
                          </Link>
                        </Box>

                        <Box component="div" className="text-center" sx={{ borderTop: "1px solid #F2F2F2" }}>
                          <Link href="#" variant="body1" fontWeight="500" sx={{ padding: "12px 0", display: "inline-block", textDecoration: "none" }} >
                            See More
                          </Link>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item md={4} sm={12} xs={12}>
                    <Box component="div" sx={{ mb: 2.5 }}>
                      <Typography component="h3" variant="h3">
                        Upcoming Holidays
                      </Typography>
                    </Box>
                    <Card sx={{ p: 0 }}>
                      <CardContent sx={{ p: 0 }}>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Events</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell className="text-end">Create Event</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                  <Box component="div" className="d-flex align-items-center">
                                    <Avatar src={require("../../assets/images/icon-candy.png")} sx={{ width: 25, height: 25, marginRight: 1 }} />
                                    <Typography component="p" variant="h6" className="fw-medium">Christmas</Typography>
                                  </Box>
                                </TableCell>
                                <TableCell>12/24/2022</TableCell>
                                <TableCell className="text-end"><AddCircleOutlineIcon fontSize="small" /></TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>

                        <Box component="div" className="text-center" sx={{ borderTop: "1px solid #F2F2F2" }}>
                          <Link href="#" variant="body1" fontWeight="500" sx={{ padding: "12px 0", display: "inline-block", textDecoration: "none" }} >
                            See More
                          </Link>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item md={4} sm={12} xs={12}>
                    <Box component="div" sx={{ mb: 2.5 }}>
                      <Typography component="h3" variant="h3">
                        Current Offers
                      </Typography>
                    </Box>
                    <Card sx={{ p: 0 }}>
                      <CardContent sx={{ p: 0 }}>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Events</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>&nbsp;</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                  <Box component="div" className="d-flex align-items-center" sx={{ paddingLeft: "12px" }}>
                                    <AvatarGroup total={2}>
                                      <Avatar alt="Resturent" src={require("../../assets/images/monogram01.png")} sx={{ width: 33, height: 33 }} />
                                      <Avatar alt="Resturent" src={require("../../assets/images/monogram02.png")} sx={{ width: 33, height: 33 }} />
                                    </AvatarGroup>
                                    <Typography component="h6" variant="h6" className="fw-medium" marginLeft={1}>2 for 1 Tuesdays</Typography>
                                  </Box>
                                </TableCell>
                                <TableCell>
                                  <Typography component="h6" variant="h6" sx={{ color: "#5D5D5D" }}>2/12 3pm</Typography>
                                </TableCell>
                                <TableCell className="text-end">
                                  <ChevronRightIcon className="text-end" size="small" sx={{ color: "#CECECE" }} /></TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>

                        <Box component="div" className="text-center" sx={{ borderTop: "1px solid #F2F2F2" }}>
                          <Link href="#" variant="body1" fontWeight="500" sx={{ padding: "12px 0", display: "inline-block", textDecoration: "none" }} >
                            See More
                          </Link>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={12} sm={12} xs={12}>
                <Carousel>
                  <Paper>
                    <Grid item md={12} sm={12} xs={12}>
                      <Grid container spacing={3}>
                        <Grid item md={12} sm={12} xs={12}>
                          <Box component="div" sx={{ mb: 2.5, display: "flex", flexDirection: { sm: "row", xs: "column" }, alignItems: { sm: "center", xs: "flex-start" }, gap: { sm: 0, xs: 2 } }}>
                            <Typography component="h3" variant="h3" >
                              Quick Analytics
                            </Typography>

                            <Box component="div" sx={{ ml: { sm: 3, xs: 0 }, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                              <Avatar src={require("../../assets/images/monogram01.png")} alt="Resturent" sx={{ mr: 2 }} />
                              <Typography component="h4" variant="h5" fontWeight="500">Tandoori Grill</Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item md={4} sm={12} xs={12}>
                          <Card sx={{ p: 0 }}>
                            <CardContent sx={{ p: 0 }}>
                              <TableContainer>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Post</TableCell>
                                      <TableCell>Views</TableCell>
                                      <TableCell>Orders Placed</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {quickAnalyticsRow.map((row, i) => (
                                      <TableRow
                                        key={i}
                                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                      >
                                        <TableCell component="th" scope="row">{row.post}</TableCell>
                                        <TableCell>{row.views}</TableCell>
                                        <TableCell>{row.orderplaced}</TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>

                              <Box component="div" className="text-center" sx={{ borderTop: "1px solid #F2F2F2" }}>
                                <Link href="#" variant="body1" fontWeight="500" sx={{ padding: "12px 0", display: "inline-block", textDecoration: "none" }} >
                                  See More
                                </Link>
                              </Box>
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item md={4} sm={12} xs={12}>
                          <Card>
                            <CardContent sx={{ p: 0 }}>
                              <Box component="div">
                                <HighchartsReact
                                  highcharts={Highcharts}
                                  options={(weeklyViews || {})}
                                />
                              </Box>
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item md={4} sm={12} xs={12}>
                          <Card>
                            <CardContent sx={{ p: 0 }}>
                              <Box component="div">
                                <Typography component="h3" variant="h3" >
                                  Christmas
                                </Typography>

                                <Typography component="h3" variant="h3" >
                                  52 <small>Views</small>
                                </Typography>
                                <ProgressBar key={1} bgcolor={"#ffc600"} percentage={10} label="02/10/2022" />

                                <Typography component="h3" variant="h3" >
                                  157 <small>Views</small>
                                </Typography>
                                <ProgressBar key={1} bgcolor={"#ffc600"} percentage={25} label="05/10/2022" />

                                <Divider sx={{ borderColor: "#C6C6C6", pt: 0, mb: 3 }} />

                                <Typography component="h3" variant="h3" >
                                  Super Bowl
                                </Typography>

                                <Typography component="h3" variant="h3" >
                                  52 <small>Views</small>
                                </Typography>
                                <ProgressBar key={1} bgcolor={"#ffc600"} percentage={40} label="02/10/2022" />

                                <Typography component="h3" variant="h3" >
                                  157 <small>Views</small>
                                </Typography>
                                <ProgressBar key={1} bgcolor={"#ffc600"} percentage={67} label="05/10/2022" />
                              </Box>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>


                  <Paper>
                    <Grid item md={12} sm={12} xs={12}>
                      <Grid container spacing={3}>
                        <Grid item md={12} sm={12} xs={12}>
                          <Box component="div" sx={{ mb: 2.5, display: "flex", flexDirection: { sm: "row", xs: "column" }, alignItems: { sm: "center", xs: "flex-start" }, gap: { sm: 0, xs: 2 } }}>
                            <Typography component="h3" variant="h3" >
                              Quick Analytics
                            </Typography>

                            <Box component="div" sx={{ ml: { sm: 3, xs: 0 }, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                              <Avatar src={require("../../assets/images/monogram01.png")} alt="Resturent" sx={{ mr: 2 }} />
                              <Typography component="h4" variant="h5" fontWeight="500">Starbucks</Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item md={4} sm={12} xs={12}>
                          <Card sx={{ p: 0 }}>
                            <CardContent sx={{ p: 0 }}>
                              <TableContainer>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Post</TableCell>
                                      <TableCell>Views</TableCell>
                                      <TableCell>Orders Placed</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {quickAnalyticsRow.map((row, i) => (
                                      <TableRow
                                        key={i}
                                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                      >
                                        <TableCell component="th" scope="row">{row.post}</TableCell>
                                        <TableCell>{row.views}</TableCell>
                                        <TableCell>{row.orderplaced}</TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>

                              <Box component="div" className="text-center" sx={{ borderTop: "1px solid #F2F2F2" }}>
                                <Link href="#" variant="body1" fontWeight="500" sx={{ padding: "12px 0", display: "inline-block", textDecoration: "none" }} >
                                  See More
                                </Link>
                              </Box>
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item md={4} sm={12} xs={12}>
                          <Card>
                            <CardContent sx={{ p: 0 }}>
                              <Box component="div">
                                <HighchartsReact
                                  highcharts={Highcharts}
                                  options={(weeklyViews || {})}
                                />
                              </Box>
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item md={4} sm={12} xs={12}>
                          <Card>
                            <CardContent sx={{ p: 0 }}>
                              <Box component="div">
                                <Typography component="h3" variant="h3" >
                                  Christmas
                                </Typography>

                                <Typography component="h3" variant="h3" >
                                  52 <small>Views</small>
                                </Typography>
                                <ProgressBar key={1} bgcolor={"#ffc600"} percentage={10} label="02/10/2022" />

                                <Typography component="h3" variant="h3" >
                                  157 <small>Views</small>
                                </Typography>
                                <ProgressBar key={1} bgcolor={"#ffc600"} percentage={25} label="05/10/2022" />

                                <Divider sx={{ borderColor: "#C6C6C6", pt: 0, mb: 3 }} />

                                <Typography component="h3" variant="h3" >
                                  Super Bowl
                                </Typography>

                                <Typography component="h3" variant="h3" >
                                  52 <small>Views</small>
                                </Typography>
                                <ProgressBar key={1} bgcolor={"#ffc600"} percentage={40} label="02/10/2022" />

                                <Typography component="h3" variant="h3" >
                                  157 <small>Views</small>
                                </Typography>
                                <ProgressBar key={1} bgcolor={"#ffc600"} percentage={67} label="05/10/2022" />
                              </Box>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Carousel>
              </Grid>
            </Grid>
          </Box>
        </Container >
      </>
    );
  }
}

export default injectIntl(withRouter(Home));